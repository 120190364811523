<template>
<div class="dashboard">
    <Transition name="fade">
      <Loader v-if="$store.state.globalLoader.show" :header="$store.state.globalLoader.head" :key="$store.state.globalLoader.head" :subheader="$store.state.globalLoader.subheader"></Loader>
    </Transition>
    <Nav v-if="$store.state.authIsReady"></Nav>
    <div class="md:pl-64 flex flex-col flex-1" v-if="$store.state.authIsReady">
      <main class="flex-1 min-h-screen">
        <div class="py-6">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 class="text-2xl font-semibold text-slate-200 mt-2 mb-2" v-if="$route.meta.title !== undefined">{{$route.meta.title}}</h1>
          </div>
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <router-view></router-view>
          </div>
        </div>
      </main>
      <footer v-if="$store.state.authIsReady" class="bg-slate-800">
    <div class="mx-auto max-w-7xl py-3 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
      <div class=" md:order-1 md:mt-0 w-full">
        <p class="text-center text-xs text-slate-400">&copy; 2022 Powered by <a href="https://complaia.systems/" class="transition ease-in-out duration-300 text-transparent font-medium bg-clip-text bg-gradient-to-r from-purple-500 to-blue-500 opacity-90 rounded-md hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Complaia Systems</a></p>
      </div>
    </div>
  </footer>
    </div>
    <!-- <div v-if="$store.state.authIsReady">{{ $store.state.user.email }}</div>
    <button v-if="$store.state.authIsReady" @click="logout()">Logout</button> -->

  </div>
</template>

<script>
    import Nav from '../components/Nav.vue';
    import Loader from '../components/Loader.vue';
    import { Menu, MenuButton, MenuItem, MenuItems, Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
    import { MenuIcon as Bars3Icon, XIcon as XMarkIcon } from '@heroicons/vue/solid'
    //import axios from 'axios';
    import {db, auth} from "@/firebase/gfbconf.js"
    export default {
        name: "Template",
        data()
        {
            return {
            }
        },
        components: {Nav,Loader,Menu, MenuButton, MenuItem, MenuItems, Popover, PopoverButton, PopoverPanel, Bars3Icon, XMarkIcon},
        created()
        {
            // Auth guard
            const loginSubscription = auth.onAuthStateChanged(async (user) => {
                if(user)
                {
                    this.$store.commit("setUser", user);
                    
                    // Get user data by id from db
                    let userCred = await db.collection("UsersData").doc(this.$store.state.user.uid).get();
                    if(userCred.exists)
                    {
                        this.$store.commit('setUserCredentials', userCred.data());
                        this.$store.commit("setAuthIsReady", true);
                        this.$store.commit("resetGlobalLoader");
                    }
                    else
                    {
                        this.$router.push("/");
                    }
                    
                }
                else
                {
                    this.$router.push("/");
                }

            })
        },
        watch:{
            '$route'()
            {
                this.$store.commit("resetGlobalLoader");
            }
        },
        methods:
        {
            toHub()
            {
                this.$router.push('/hub');
            },
            async logout() {
                try {
                    await this.$store.dispatch("logout");
                    this.$router.push("/");
                } catch (err) {
                    console.log(err.message);
                }
            }
        },
        computed:
        {
        }
    }
</script>
<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>