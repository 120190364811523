<template>
  <div>
    <p class="mb-4 truncate text-sm text-slate-200">Skontaktuj się z obsługą Lauron.</p>
    <div class="text-slate-200">
        <div Class="divide-y-2 divide-slate-700">
    <div class="mt-8">
      <div class="divide-y-2 divide-slate-700">
        <div class="lg:grid lg:grid-cols-1 lg:gap-8">
          <div class="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3 sm:gap-x-8 sm:gap-y-12 lg:col-span-2 lg:mt-0">
            <div>
              <h3 class="text-lg font-medium leading-6 text-slate-400">Firma</h3>
              <dl class="mt-2 text-base">
                <div>
                  <dt class="sr-only">Nazwa firmy</dt>
                  <dd>Lauron Sp. z o.o.</dd>
                </div>
                <div class="mt-1">
                  <dt class="sr-only">Ulica</dt>
                  <dd>Płotowo 3A</dd>
                </div>
                <div class="mt-1">
                  <dt class="sr-only">Kod pocztowy oraz miasto</dt>
                  <dd>77-131 Rekowo</dd>
                </div>
                <div class="mt-1">
                  <dt class="sr-only">NIP</dt>
                  <dd>NIP: 842-177-21-06</dd>
                </div>
                <div class="mt-1">
                  <dt class="sr-only">REGON</dt>
                  <dd>REGON: 360796862</dd>
                </div>
                <div class="mt-1">
                  <dt class="sr-only">KRS</dt>
                  <dd>KRS: 0000543828</dd>
                </div>
              </dl>
            </div>
            <div>
              <h3 class="text-lg font-medium leading-6 text-slate-400">Obsługa</h3>
              <dl class="mt-2 text-base">
                <div>
                  <dt class="sr-only">E-mail</dt>
                  <dd>rafal@wbtank.pl</dd>
                </div>
                <div class="mt-1">
                  <dt class="sr-only">Numer telefonu</dt>
                  <dd>+48 59 822 33 44</dd>
                  <dd class="text-slate-400">lub</dd>
                  <dd>+48 601 98 35 35</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
  </div>
</template>

<script>
export default {
    data()
        {
            return {
            }
        },
    components: {
    },
    methods: {
    },
    created(){
    }
}
</script>

<style scoped>

</style>