<template>
  <div id="app" class="min-h-screen w-screen bg-slate-600">
    <transition name="fade">
      <Notification v-if="$store.state.notification.show"></Notification>
    </transition>
    <router-view />
  </div>
  
</template>

<script>
  import Notification from '@/components/Notification.vue';
  export default {
    components: {Notification},
    watch: {
      // Set meta tags
      $route(to) {
        this.setMeta(to.meta);
      },
    },
    methods: {
      setMeta(meta) {
        document.title = meta.pageTitle;
        if(meta.title === undefined)
        {
          document.title = 'Lauron - klient';
        }
      }
    }
  }
</script>
<style>

body
{
  overflow-x: hidden;
}

</style>
<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
