import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Login',
    meta: { pageTitle: "Zaloguj się do systemu", title: 'Zaloguj się do systemu' },
    component: () => import(/* webpackChunkName: "Home" */ '../views/Login.vue')
  },
  {
    path: '/reset',
    name: 'ResetPassword',
    meta: { pageTitle: "Zresetuj hasło", title: 'Zresetuj hasło' },
    component: () => import(/* webpackChunkName: "Home" */ '../views/ResetPassword.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue'),
    children: [
      {
        path: 'profile',
        component: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue'),
        meta: { pageTitle: "Zarządzaj kontem - Lauron Sp. z o.o.", title: 'Twoje konto' }
      },
      {
        path: 'mycards',
        component: () => import(/* webpackChunkName: "about" */ '../views/MyCards.vue'),
        meta: { pageTitle: "Zarządzaj kartami - Lauron Sp. z o.o.", title: 'Zarządzaj swoimi kartami' }
      },
      {
        path: 'card',
        component: () => import(/* webpackChunkName: "about" */ '../views/CardDetails.vue'),
        meta: { pageTitle: "Szczegóły karty - Lauron Sp. z o.o.", title: 'Szczegóły Twojej karty' }
      },
      {
        path: 'transactions',
        component: () => import(/* webpackChunkName: "about" */ '../views/Transactions.vue'),
        meta: { pageTitle: "Transakcje - Lauron Sp. z o.o.", title: 'Szczegóły transakcyjne' }
      },
      {
        path: 'contact',
        component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue'),
        meta: { pageTitle: "Kontakt - Lauron Sp. z o.o.", title: 'Kontakt' }
      },
      {
        path: 'invoices',
        component: () => import(/* webpackChunkName: "about" */ '../views/InvoiceList.vue'),
        meta: { pageTitle: "Faktury - Lauron Sp. z o.o.", title: 'Faktury' }
      },
      {
        path: 'interestNotes',
        component: () => import(/* webpackChunkName: "about" */ '../views/InterestNoteList.vue'),
        meta: { pageTitle: "Nota odsetkowa - Lauron Sp. z o.o.", title: 'Nota odsetkowa' }
      },
      {
        path: 'contracts',
        component: () => import(/* webpackChunkName: "about" */ '../views/Contracts.vue'),
        meta: { pageTitle: "Umowy - Lauron Sp. z o.o.", title: 'Umowy' }
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
