import { createApp } from 'vue'
import FloatingVue from 'floating-vue';
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import '@/styles/tooltip.css'

createApp(App).use(store).use(router).use(FloatingVue, {themes: {
    transactions: {
        '$resetCss': true,
        triggers: ['hover'],
        delay: {
          show: 300,
          hide: 300,
        },
        autoHide: true,
        eagerMount: true,
        placement: 'top',
      },
  },}).mount('#app')
