<template>
  <div id="login" class="h-screen bg-slate-200">
    <transition name="fade">
      <Loader v-if="$store.state.globalLoader.show" :header="$store.state.globalLoader.head" :key="$store.state.globalLoader.head" :subheader="$store.state.globalLoader.subheader"></Loader>
    </transition>
    <div class="min-h-full flex">
      <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div class="mx-auto w-full max-w-sm lg:w-96">
          <transition name="fade">
            <div class="rounded-md bg-red-50 p-4 transition-all mb-6" v-show="error">
              <div class="flex">
                <div class="ml-3">
                  <h3 class="text-sm font-medium text-red-800">
                    Coś poszło nie tak w trakcie procesu logowania. Podejmij następujące akcje:
                  </h3>
                  <div class="mt-2 text-sm text-red-700">
                    <ul role="list" class="list-disc pl-5 space-y-1">
                      <li>
                        Sprawdź e-mail lub hasło.
                      </li>
                      <li>
                        Sprawdź połączenie z internetem. 
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <div>
            <h2 class="mt-6 text-3xl font-extrabold text-slate-900">
              Witaj w panelu klienta!
            </h2>
            <p class="mt-2 text-sm text-slate-600">
              Lauron Sp. z o.o.
            </p>
          </div>

          <div v-if="!passwordReset" class="mt-6">
            <div>
              <div class="mt-6 relative">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="w-full border-t border-slate-400" />
                </div>
                <div class="relative flex justify-center text-sm">
                  <span class="px-2 bg-slate-200 text-slate-600">
                    Wpisz swój email oraz hasło, aby się zalogować.
                  </span>
                </div>
              </div>
            </div>

            <div class="mt-6">
              <form action="#" method="POST" class="space-y-6">
                <div>
                  <label for="email" class="block text-sm font-medium text-slate-700">
                    E-mail
                  </label>
                  <div class="mt-1">
                    <input :disabled="automaticLogin" :class="[automaticLogin ? 'opacity-30 pointer-events-none' : '']" id="email" placeholder="E-mail" name="email" type="email" v-model="email" autocomplete="email" required="" class="appearance-none block w-full px-3 py-2 border border-slate-300 rounded-md shadow-sm placeholder-slate-400 focus:outline-none focus:ring-blue-400 focus:border-blue-400 sm:text-sm transition-all" />
                  </div>
                </div>

                <div class="space-y-1">
                  <label for="password" class="block text-sm font-medium text-slate-700">
                    Hasło
                  </label>
                  <div class="mt-1">
                    <input :disabled="automaticLogin" :class="[automaticLogin ? 'opacity-30 pointer-events-none' : '']" id="password" placeholder="Hasło" name="password" v-model="password" type="password" autocomplete="current-password" required="" class="appearance-none block w-full px-3 py-2 border border-slate-300 rounded-md shadow-sm placeholder-slate-400 focus:outline-none focus:ring-blue-400 focus:border-blue-400 sm:text-sm transition-all" />
                  </div>
                  <div class="flex justify-end mt-1">
                    <div @click.prevent="passwordReset = true, email = '', password = ''" class="text-xs font-medium text-blue-600 hover:text-blue-500 transition-all duration-300 w-fit select-none cursor-pointer">Nie pamiętasz hasła?</div>
                  </div>
                </div>




                <div>
                  <button :disabled="automaticLogin" :class="[automaticLogin ? 'cursor-not-allowed' : 'hover:bg-blue-500']" @click.prevent="logInUser" type="submit" class="w-full flex justify-center py-2 px-4 h-10 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-300">
                    <div v-if="automaticLogin" class="spinner"></div>
                    <div v-else>Zaloguj się</div>
                  </button>
                </div>

                <div class="text-xs text-slate-700 font-medium">
                  &copy; Powered by <a href="https://complaia.systems/" class="text-purple-500 hover:text-purple-400">Complaia Systems</a>.
                </div>

              </form>
            </div>
          </div>
          <div v-if="passwordReset" class="mt-6">
            <div>
              <div class="mt-6 relative">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="w-full border-t border-slate-400" />
                </div>
                <div class="relative flex justify-center text-sm">
                  <span class="px-2 bg-slate-200 text-slate-600">
                    Wpisz swój adres email, aby zresetować hasło.
                  </span>
                </div>
              </div>
            </div>

            <div class="mt-6">
              <form action="#" method="POST" class="space-y-6">
                <div>
                  <label for="email" class="block text-sm font-medium text-slate-700">
                    E-mail
                  </label>
                  <div class="mt-1">
                    <input :disabled="resetingPassword" :class="[resetingPassword ? 'opacity-30 pointer-events-none' : '']" id="email" placeholder="E-mail" name="email" type="email" v-model="email" autocomplete="email" required="" class="appearance-none block w-full px-3 py-2 border border-slate-300 rounded-md shadow-sm placeholder-slate-400 focus:outline-none focus:ring-blue-400 focus:border-blue-400 sm:text-sm transition-all" />
                  </div>
                  <div class="flex justify-end mt-1">
                    <div @click.prevent="passwordReset = false, email = '', password = ''" class="text-xs font-medium text-blue-600 hover:text-blue-500 transition-all duration-300 w-fit select-none cursor-pointer">Wróć do panelu logowania</div>
                  </div>
                </div>

                <div>
                  <button :disabled="resetingPassword || email.length === 0" :class="email.length === 0 ? 'bg-gray-400 cursor-not-allowed' : 'hover:bg-blue-500 bg-blue-400 cursor-pointer'" @click.prevent="resetPassword" type="submit" class="w-full flex justify-center py-2 px-4 h-10 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-300">
                    <div v-if="resetingPassword" class="spinner"></div>
                    <div v-else>Wyślij link do zresetowania hasła</div>
                  </button>
                </div>

                <div class="text-xs text-slate-700 font-medium">
                  &copy; Powered by <a href="https://complaia.systems/" class="text-purple-500 hover:text-purple-400">Complaia Systems</a>.
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden lg:block relative w-0 flex-1">
        <img class="absolute inset-0 h-full w-full object-cover" src="https://firebasestorage.googleapis.com/v0/b/lauron-a8b59.appspot.com/o/assets%2Flogin_panel.jpeg?alt=media&token=3274b773-1c04-4e6e-b617-359c3d84ab23" alt="System wykonany przez Complaia Systems" />
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '../components/Loader.vue';
import axios from 'axios'

export default {
  name: "Login",
  components: {
    Loader
  },
   watch:{
    '$route'()
    {
      this.$store.commit("resetGlobalLoader");
    }
  },
  data: function () {
    return {
      error: false,
      email: "",
      password: "",
      automaticLogin: false,
      passwordReset: false,
      resetingPassword: false
    }
  },
  methods: {
    async resetPassword() {
      try {
        this.resetingPassword = true

        const res = await axios.post("https://europe-central2-lauron-a8b59.cloudfunctions.net/api/generatePasswordReset", {
            email: this.email
        })
        if(res.data.success) {
          this.$store.commit('setNotification', {
            show: true,
            head: "Link do zresetowania hasła został wysłany",
            subheader: `Sprawdź swoją skrzynkę pocztową`,
            success: true
          });
          this.email = ""
          this.password = ""
          this.passwordReset = false
          this.resetingPassword = false
        } else {
          this.$store.commit('setNotification', {
            show: true,
            head: "Pojawił się błąd przy resetowaniu hasła",
            subheader: `Zamknij okno i spróbuj ponownie`,
            success: false
          });
          this.email = ""
          this.password = ""
          this.passwordReset = false
          this.resetingPassword = false
        }
      } catch(error) {
        this.$store.commit('setNotification',{
          show: true,
          head: "Pojawił się błąd przy resetowaniu hasła",
          subheader: `Zamknij okno i spróbuj ponownie`,
          success: false
        });
        this.email = ""
        this.password = ""
        this.passwordReset = false
        this.resetingPassword = false
      }
    },
    async logInUser() {
      try {
        await this.$store.dispatch("login", {
          email: this.email,
          password: this.password,
        });
        this.error = false;
        this.$router.push(`/dashboard/transactions`);
      } catch (err) {
        this.error = true;
        console.log(err.message);
      }
    },
    async loginUserWithToken() {
      try {
        if(this.$route.query.value !== undefined && this.$route.query.value !== null && this.$route.query.value.length > 0) {
          this.automaticLogin = true
          this.email = "Automatyczne logowanie"
          this.password = "Automatyczne logowanie"
          const token = decodeURIComponent(this.$route.query.value)
          this.$router.replace({'query': null});
          await this.$store.dispatch("loginWithToken", {
            token,
          });
          this.error = false;
          this.$router.push(`/dashboard/transactions`);
        }
      } catch(error) {
        this.email = ""
        this.password = ""
        this.automaticLogin = false
        this.$store.commit('setNotification',{
          show: true,
          head: "Pojawił się błąd przy automatycznym logowaniu użytkownika",
          subheader: `Zamknij okno i spróbuj ponownie`,
          success: false
        }); 
      }
    }
  },
  created() {
    this.loginUserWithToken()
  }
};
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.spinner {
   width: 1.4rem;
   height: 1.4rem;
   border-radius: 50%;
   border: 5px solid #ffffff;
   animation: spinner-bulqg1 1.3s infinite linear alternate,
        spinner-oaa3wk 2.6s infinite linear;
}

@keyframes spinner-bulqg1 {
   0% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
   }

   12.5% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
   }

   25% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
   }

   50% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
   }

   62.5% {
      clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
   }

   75% {
      clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
   }

   100% {
      clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
   }
}

@keyframes spinner-oaa3wk {
   0% {
      transform: scaleY(1) rotate(0deg);
   }

   49.99% {
      transform: scaleY(1) rotate(135deg);
   }

   50% {
      transform: scaleY(-1) rotate(0deg);
   }

   100% {
      transform: scaleY(-1) rotate(-135deg);
   }
}
</style>
