<template>
  <div>
    <p class="mb-4 truncate text-sm text-slate-200">Ostatnie transakcje Twojej karty oraz jej szczegóły.</p>
  <div>
        <div v-if="!searchingCard && this.card.statusData !== undefined">
            <div class="mb-4" v-if="this.card.statusData.informationText.length > 0">
                <div class="rounded-md bg-red-100 p-4">
                    <div class="flex">
                    <div class="flex-shrink-0">
                        <InformationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div>
                    <div class="ml-3">
                        <h3 class="text-sm font-medium text-red-800">Uwaga!</h3>
                        <div class="mt-1 text-sm text-red-700">
                        <p> {{this.card.statusData.informationText}}</p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div class="mb-4" v-if="this.cardTemporaryBlockInfo">
                <div class="rounded-md bg-green-50 p-4">
                    <div class="flex">
                    <div class="flex-shrink-0">
                        <InformationCircleIcon class="h-5 w-5 text-green-500" aria-hidden="true" />
                    </div>
                    <div class="ml-3">
                        <h3 class="text-sm font-medium text-green-800">Uwaga!</h3>
                        <div class="mt-1 text-sm text-green-700">
                        <p> Jeżeli Twoja karta nie została jeszcze zablokowana, wróć tutaj za jakiś czas. Blokowanie karty może zająć chwilkę dłużej, prosimy o cierpliwość. Jeżeli karta nie zostanie zablokowana w przeciągu godziny, spróbuj ponownie. W przypadku napotkania problemu prosimy o kontakt mailowy na adres rafal@wbtank.pl</p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div class="mb-4" v-if="this.cardUnlockInfo">
                <div class="rounded-md bg-green-50 p-4">
                    <div class="flex">
                    <div class="flex-shrink-0">
                        <InformationCircleIcon class="h-5 w-5 text-green-500" aria-hidden="true" />
                    </div>
                    <div class="ml-3">
                        <h3 class="text-sm font-medium text-green-800">Uwaga!</h3>
                        <div class="mt-1 text-sm text-green-700">
                        <p> Jeżeli Twoja karta nie została jeszcze odblokowana, wróć tutaj za jakiś czas. Odblokowanie karty może zająć chwilkę dłużej, prosimy o cierpliwość. Jeżeli karta nie zostanie odblokowana w przeciągu godziny, spróbuj ponownie. W przypadku napotkania problemu prosimy o kontakt mailowy na adres rafal@wbtank.pl</p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <LockClosedIcon class="h-4 w-4 bg-red-500" aria-hidden="true"></LockClosedIcon> -->
        <div class="grid lg:gap-y-8 lg:gap-x-8 2xl-gap-x-0 w-full grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3">
            <div v-if="!searchingCard" :class="{'transition select-none duration-500 rounded-xl relative border border-transparent text-white shadow-2xl transform': true}">
                <img @load="card.loaded = true" class="relative object-cover w-full h-full rounded-xl" :src="card.imgUrl">
                <div v-if="card.loaded" class="absolute top-0 right-0 flex justify-center items-center">
                    <span :class="{'inline-flex z-50 items-center text-xs rounded-tr-lg bg-white px-2.5 py-1 font-medium':true, 'text-green-800': this.card.statusData.badgeColor === 'green', 'text-yellow-800':  this.card.statusData.badgeColor === 'orange', 'text-red-800':  this.card.statusData.badgeColor === 'red'}">
                        <svg :class="{'-ml-0.5 mr-1.5 h-2 w-2': true, 'text-green-400': this.card.statusData.badgeColor === 'green', 'text-yellow-400':  this.card.statusData.badgeColor === 'orange', 'text-red-400':  this.card.statusData.badgeColor === 'red'}" fill="currentColor" viewBox="0 0 8 8">
                        <circle cx="4" cy="4" r="3" />
                        </svg>
                        {{this.card.statusData.badgeTextPL}}
                    </span>
                </div>

                <div v-if="card.loaded" class="w-full px-8 py-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <div class="flex justify-between">
                        <div class="">
                            <p class="font-light text-sm sm:text-base lg:text-sm">
                                Użytkownik
                            </p>
                            <p class="font-medium text-sm sm:text-base lg:text-sm tracking-widest">
                                {{$store.state.userData.company.name !== undefined && $store.state.userData.company.name !== null && $store.state.userData.company.name.length > 0 ? $store.state.userData.company.name.length > 20 ? $store.state.userData.company.name.substring(0,20) + '...' : $store.state.userData.company.name : '--'}}
                            </p>
                        </div>
                        <img class="w-10 h-10 sm:w-14 sm:h-14 lg:w-10 lg:h-10 xl:h-14 xl:w-14 2x:h-10 2xl:w-10" src="https://firebasestorage.googleapis.com/v0/b/lauron-a8b59.appspot.com/o/assets%2Fshell-4-logo-svg-vector.svg?alt=media&token=97cdc441-3bfe-4a0a-8296-3d6d3dce0135"/>
                    </div>
                    <div v-if="card.PAN !== null && card.PAN !== undefined" class="pt-2 sm:pt-6 lg:pt-2 xl:pt-6 2xl:pt-2">
                        <p class="font-light text-sm sm:text-base lg:text-sm">
                            Numer karty
                        </p>
                        <p class="font-medium text-sm sm:text-base lg:text-sm tracking-more-wider">
                            {{card.PAN.length > 0 ? addSpaces(card.PAN) : '--'}}
                        </p>
                    </div>
                    <div class="pt-5 sm:pt-10 lg:pt-5 xl:pt-10 2xl:pt-5 pr-6">
                        <div class="flex justify-between">
                            <div v-if="card.VRN !== null || card.DriverName !== null" class="">
                                <p class="font-light text-xs sm:text-sm lg:text-xs">
                                    {{card.VRN !== null && card.VRN.length > 0 ? 'Numer rejestracyjny' : 'Kierowca' }}
                                </p>
                                <p class="font-medium tracking-wider text-xs sm:text-sm lg:text-xs">
                                    {{card.VRN !== null && card.VRN.length > 0 ? card.VRN : card.DriverName }}
                                </p>
                            </div>
                            <div v-if="card.ExpiryDate !== null && card.ExpiryDate !== undefined" class="">
                                <p class="font-light text-xs sm:text-sm lg:text-xs">
                                    Ważna do
                                </p>
                                <p  class="font-medium tracking-wider text-xs sm:text-sm lg:text-xs">
                                    {{card.ExpiryDate[4]+card.ExpiryDate[5]}}/{{card.ExpiryDate[2]+card.ExpiryDate[3]}}
                                </p>
                            </div>
    
                            <!-- <div class="">
                                <p class="font-light text-xs sm:text-sm lg:text-xs">
                                    Numer
                                </p>
                                <p class="font-bold tracking-more-wider text-xs sm:text-sm lg:text-xs">
                                    3
                                </p>
                            </div> -->
                        </div>
                    </div>
    
                </div>
            </div>
            <div v-if="searchingCard" :class="{'transition select-none duration-500 rounded-xl relative border border-transparent text-white shadow-2xl transform': true}">
                <!-- {{(index+1)%3}} -->
                <!-- <img class="relative object-cover w-full h-full rounded-xl" src="https://firebasestorage.googleapis.com/v0/b/wbtank-65cca.appspot.com/o/assets%2Fshell_card_awers.jpg?alt=media&token=92d98e44-42a7-4142-beb8-4b9c8c40c29e">
                <div class="w-full px-9% pt-12% absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <span class="text-lg text-slate-500 whitespace-nowrap text-shadow font-medium font-sans tracking-widest">4642  3489  9867  7632</span>
                </div> -->
                <img @load="loadedImg = true" class="relative object-cover w-full h-full rounded-xl" src="https://firebasestorage.googleapis.com/v0/b/lauron-a8b59.appspot.com/o/assets%2F1_fseafaww1-min.jpeg?alt=media&token=37f53c89-0682-4407-9640-c949b44d7fda">
                
                <div v-if="loadedImg" class="w-full px-8 py-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <div class="flex justify-between">
                        <LoaderData class="py-6"></LoaderData>
                        <img class="w-10 h-10 sm:w-14 sm:h-14 lg:w-10 lg:h-10 xl:h-14 xl:w-14 2x:h-10 2xl:w-10" src="https://firebasestorage.googleapis.com/v0/b/lauron-a8b59.appspot.com/o/assets%2Fshell-4-logo-svg-vector.svg?alt=media&token=97cdc441-3bfe-4a0a-8296-3d6d3dce0135"/>
                    </div>
                    <div class="pt-2 sm:pt-6 lg:pt-2 xl:pt-6 2xl:pt-2">
                        <LoaderData class="py-4"></LoaderData>
                    </div>
                    <div class="pt-5 sm:pt-10 lg:pt-5 xl:pt-10 2xl:pt-5 pr-6">
                        <div class="flex justify-between">
                            <LoaderData class="py-5"></LoaderData>
                            <LoaderData class="py-5"></LoaderData>
                        </div>
                    </div>
    
                </div>
            </div>
            <div class="mt-4 lg:mt-0 2xl:col-span-2">
                <div class="overflow-hidden bg-slate-600">
                    <div class="flex justify-between items-center">
                        <div class="py-2">
                            <h3 class="text-lg font-medium leading-6 text-slate-50">Dane podstawowe karty</h3>
                            <p class="mt-1 max-w-2xl text-sm text-slate-100">Informacje o karcie oraz jej użytkowniku.</p>
                        </div>
                        <!-- {{this.card.statusData}} -->
                        <!-- <div v-if="!searchingCard" class="flex justify-center items-center">
                            <span :class="{'inline-flex items-center rounded-md bg-white px-2.5 py-0.5 text-sm font-medium':true, 'text-green-800': this.card.statusData.badgeColor === 'green', 'text-yellow-800':  this.card.statusData.badgeColor === 'orange', 'text-red-800':  this.card.statusData.badgeColor === 'red'}">
                                <svg :class="{'-ml-0.5 mr-1.5 h-2 w-2': true, 'text-green-400': this.card.statusData.badgeColor === 'green', 'text-yellow-400':  this.card.statusData.badgeColor === 'orange', 'text-red-400':  this.card.statusData.badgeColor === 'red'}" fill="currentColor" viewBox="0 0 8 8">
                                <circle cx="4" cy="4" r="3" />
                                </svg>
                                {{this.card.statusData.badgeTextPL}}
                            </span>
                        </div> -->
                        <div v-if="!searchingCard" class="inline-flex rounded-md shadow-sm">
                            <Menu as="div" class="relative -ml-px block mr-px">
                            <MenuButton class="relative inline-flex items-center rounded-md border border-slate-300 bg-white py-1 px-1 text-sm font-medium text-slate-500 hover:bg-slate-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
                                <span class="sr-only">Open options</span>
                                <span class="text-xs inline-flex items-center bg-white px-1 font-medium text-slate-700 hover:bg-slate-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">Zarządzaj</span>
                                <ChevronDownIcon class="h-4 w-4" aria-hidden="true" />
                            </MenuButton>
                            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                                <MenuItems class="absolute right-0 z-10 mt-1 -0 w-60 origin-top-right rounded-md bg-white shadow-lg border border-slate-500 ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div class="divide-y divide-slate-200">
                                    <MenuItem v-slot="{ active }">
                                    <div @click.prevent="blockAsk = true" :class="[card.TemporaryBlockAllowed && !cardTemporaryBlockInfo ? active ? 'bg-slate-100 text-slate-900 cursor-pointer' : 'text-slate-700 cursor-pointer' : 'text-slate-700 pointer-events-none opacity-50', 'transition ease-in-out duration-200 rounded-t-md flex justify-between items-center px-4 py-2 text-sm']">Zablokuj tymczasowo kartę <LockClosedIcon v-if="!card.TemporaryBlockAllowed || (card.TemporaryBlockAllowed && cardTemporaryBlockInfo)" class="h-5 w-5 text-slate-600" aria-hidden="true"></LockClosedIcon><ClockIcon v-if="card.TemporaryBlockAllowed && !cardTemporaryBlockInfo" class="h-5 w-5 text-slate-600" aria-hidden="true"></ClockIcon> </div>
                                    </MenuItem>
                                    <MenuItem v-slot="{ active }">
                                    <div @click.prevent="unlockAsk = true" :class="[card.UnblockAllowed && !cardUnlockInfo ? active ? 'bg-slate-100 text-slate-900 cursor-pointer' : 'text-slate-700 cursor-pointer' : 'text-slate-700 pointer-events-none opacity-50', 'transition ease-in-out duration-200 rounded-t-md flex justify-between items-center px-4 py-2 text-sm']">Odblokuj kartę <LockClosedIcon v-if="!card.UnblockAllowed || (card.UnblockAllowed && cardUnlockInfo)" class="h-5 w-5 text-slate-600" aria-hidden="true"></LockClosedIcon><LockOpenIcon v-if="card.UnblockAllowed && !cardUnlockInfo" class="h-5 w-5 text-slate-600" aria-hidden="true"></LockOpenIcon> </div>
                                    </MenuItem>
                                    <MenuItem v-slot="{ active }">
                                    <div @click.prevent="showRestrictionsModal = true" :class="[active ? 'bg-slate-100 text-slate-900 cursor-pointer' : 'text-slate-700 cursor-pointer', 'transition ease-in-out duration-200 rounded-b-md flex justify-between items-center px-4 py-2 text-sm']">Restrykcje <CreditCardIcon class="h-5 w-5 text-slate-600" aria-hidden="true"></CreditCardIcon> </div>
                                    </MenuItem>
                                </div>
                                </MenuItems>
                            </transition>
                            </Menu>
                        </div>
                        <div v-if="searchingCard" class="flex justify-center items-center text-xl font-semibold tracking-tight text-slate-900">
                            <LoaderData></LoaderData>
                        </div>
                    </div>
                    <div class="">
                    <dl>
                        <div class="bg-slate-700 py-4 px-4 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt class="text-sm font-medium text-slate-400">Typ karty</dt>
                        <dd v-if="!searchingCard" class="mt-1 text-sm text-slate-100 sm:col-span-2 sm:mt-0">{{this.card.IsVirtualCard ? 'Karta wirtualna' : 'Karta fizyczna'}}</dd>
                        <dd v-if="searchingCard" class="text-xl font-semibold tracking-tight text-slate-900"><LoaderData class="mt-1"></LoaderData></dd>
                        </div>
                        <div class="bg-slate-600 py-4 px-4 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt class="text-sm font-medium text-slate-400">Numer rejestracyjny pojazdu</dt>
                        <dd v-if="!searchingCard" class="mt-1 text-sm text-slate-100 sm:col-span-2 sm:mt-0">{{this.card.VRN !== null && this.card.VRN.length > 0 ? this.card.VRN : '--'}}</dd>
                        <dd v-if="searchingCard" class="text-xl font-semibold tracking-tight text-slate-900"><LoaderData class="mt-1"></LoaderData></dd>
                        </div>
                        <div class="bg-slate-700 py-4 px-4 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt class="text-sm font-medium text-slate-400">Kierowca</dt>
                        <dd v-if="!searchingCard" class="mt-1 text-sm text-slate-100 sm:col-span-2 sm:mt-0">{{this.card.DriverName !== null && this.card.DriverName.length > 0 ? this.card.DriverName : '--'}}</dd>
                        <dd v-if="searchingCard" class="text-xl font-semibold tracking-tight text-slate-900"><LoaderData class="mt-1"></LoaderData></dd>
                        </div>
                    </dl>
                    </div>
                </div>
            </div>
            <dl class="lg:col-span-2 2xl:col-span-3">
                <div class="bg-slate-600 lg:bg-slate-700 py-4 px-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt class="text-sm font-medium text-slate-400">Użytkownik karty</dt>
                <dd v-if="!searchingCard" class="mt-1 text-sm text-slate-100 sm:col-span-2 sm:mt-0">{{$store.state.userData.company.name !== undefined && $store.state.userData.company.name !== null && $store.state.userData.company.name.length > 0 ? $store.state.userData.company.name : '--'}}</dd>
                <dd v-if="searchingCard" class="text-xl font-semibold tracking-tight text-slate-900"><LoaderData class="mt-1"></LoaderData></dd>
                </div>
                <div class="bg-slate-700 lg:bg-slate-600 py-4 px-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt class="text-sm font-medium text-slate-400">Reseller</dt>
                <dd v-if="!searchingCard" class="mt-1 text-sm text-slate-100 sm:col-span-2 sm:mt-0">{{this.card.AccountShortName !== null & this.card.AccountShortName.length > 0 ? this.card.AccountShortName : '--'}}</dd>
                <dd v-if="searchingCard" class="text-xl font-semibold tracking-tight text-slate-900"><LoaderData class="mt-1"></LoaderData></dd>
                </div>
                <div class="bg-slate-600 lg:bg-slate-700 py-4 px-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt class="text-sm font-medium text-slate-400">Data wydania karty</dt>
                <dd v-if="!searchingCard" class="mt-1 text-sm text-slate-100 sm:col-span-2 sm:mt-0">{{this.card.IssuedDate !== null && this.card.IssuedDate.length > 0 ? translateDate(this.card.IssuedDate, 'date') : '--'}}</dd>
                <dd v-if="searchingCard" class="text-xl font-semibold tracking-tight text-slate-900"><LoaderData class="mt-1"></LoaderData></dd>
                </div>
                <div class="bg-slate-700 lg:bg-slate-600 py-4 px-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt class="text-sm font-medium text-slate-400">Karta ważna do</dt>
                <dd v-if="!searchingCard" class="mt-1 text-sm text-slate-100 sm:col-span-2 sm:mt-0">{{this.card.ExpiryDate !== null && this.card.ExpiryDate.length > 0 ? translateDate(this.card.ExpiryDate, 'date') : '--'}}</dd>
                <dd v-if="searchingCard" class="text-xl font-semibold tracking-tight text-slate-900"><LoaderData class="mt-1"></LoaderData></dd>
                </div>
                <div class="bg-slate-600 lg:bg-slate-700 py-4 px-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt class="text-sm font-medium text-slate-400">Ostatnia edycja karty</dt>
                <dd v-if="!searchingCard" class="mt-1 text-sm text-slate-100 sm:col-span-2 sm:mt-0">{{this.card.LastModifiedDate !== null && this.card.LastModifiedDate.length > 0 ? translateDate(this.card.LastModifiedDate, 'dateWithHour') : '--'}}</dd>
                <dd v-if="searchingCard" class="text-xl font-semibold tracking-tight text-slate-900"><LoaderData class="mt-1"></LoaderData></dd>
                </div>
                <div class="bg-slate-700 lg:bg-slate-600 py-4 px-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt class="text-sm font-medium text-slate-400">Data ostatniego użycia</dt>
                <dd v-if="!searchingCard" class="mt-1 text-sm text-slate-100 sm:col-span-2 sm:mt-0">{{this.card.LastUsedDate !== null && this.card.LastUsedDate.length > 0 ? translateDate(this.card.LastUsedDate, 'LastUseDateWithHour') : '--'}}</dd>
                <dd v-if="searchingCard" class="text-xl font-semibold tracking-tight text-slate-900"><LoaderData class="mt-1"></LoaderData></dd>
                </div>
            </dl>
        </div>

        <div class="mt-4">
            <div class="py-2 flex justify-between items-center">
                <div>
                    <h3 class="text-lg font-medium leading-6 text-slate-50">Transakcje</h3>
                    <p class="mt-1 max-w-2xl text-sm text-slate-100">Informacje o transakcjach wykonanych karty.</p>
                </div>
                <div>
                    <div v-if="!searchingTransactions && transactions !== null" class="inline-flex rounded-md shadow-sm">
                        <Menu as="div" class="relative -ml-px block mr-px">
                        <MenuButton class="relative inline-flex items-center rounded-md border border-slate-300 bg-white py-1 px-1 text-sm font-medium text-slate-500 hover:bg-slate-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
                            <span class="sr-only">Open options</span>
                            <span class="text-xs inline-flex items-center bg-white px-1 font-medium text-slate-700 hover:bg-slate-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">Zestawienie karty</span>
                            <ChevronDownIcon class="h-4 w-4" aria-hidden="true" />
                        </MenuButton>
                        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                            <MenuItems class="absolute right-0 z-10 mt-1 -0 w-60 origin-top-right rounded-md bg-white shadow-lg border border-slate-500 ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div class="divide-y divide-slate-200">
                                <MenuItem v-for="(month,indx) in statementMonths" :key="indx" v-slot="{ active }">
                                <div @click.prevent="printStatement(month)" :class="[active ? 'bg-slate-100 text-slate-900 cursor-pointer' : 'text-slate-700 cursor-pointer', 'transition ease-in-out duration-200 rounded-b-md flex justify-between items-center px-4 py-2 text-sm']">{{month[1] + ' ' + translateToYear(month[0])}} <span v-if="testTBC(month[1])" class="text-xs text-gray-300">(w trakcie)</span> <DocumentTextIcon class="h-5 w-5 text-slate-600" aria-hidden="true"></DocumentTextIcon> </div>
                                </MenuItem>
                            </div>
                            </MenuItems>
                        </transition>
                        </Menu>
                    </div>
                </div>
            </div>
            <!-- <button @click.prevent="this.findTransactions()" class="bg-black p-1 text-white mr-2">TEST API TRANSACTIONS</button> -->
        </div>
        <div class="mt-2 flex flex-col">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle px-4 md:px-6 lg:px-8">
                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
                    <table class="min-w-full divide-y divide-slate-900">
                    <thead class="bg-slate-800">
                        <tr>
                        <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-slate-300 sm:pl-6">ID transakcji</th>
                        <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-300">Stacja</th>
                        <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-300">Produkt</th>
                        <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-300">Ilość</th>
                        <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-300">Kwota brutto</th>
                        <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-300">Kwota netto</th>
                        <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-300">Data transakcji</th>
                        </tr>
                    </thead>
                    <tbody v-if="!searchingTransactions && transactions !== null" class="divide-y divide-slate-700 bg-slate-500">
                        <tr @click="log(transaction)" v-for="(transaction, index) in transactions.slice(0, hitsOnPage)" :key="index" :class="[index % 2 ? 'bg-slate-700' : 'bg-slate-600']">
                        <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-slate-300 sm:pl-6"> {{transaction.TransactionId != null && transaction.TransactionId.length > 0 ? transaction.TransactionId : '--'}} </td>
                        <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-slate-100"> 
                            <LoaderData v-if="!pricesLoaded" class=""></LoaderData>
                            <div v-else>
                            <span v-tooltip="`${transaction.DelcoName != null && transaction.DelcoName.length > 0 ? transaction.DelcoName : ''}${transaction.DelcoName != null && transaction.DelcoName.length > 0 && transaction.SiteName != null && transaction.SiteName.length > 0 ? ' - ' : ''}${transaction.SiteName != null && transaction.SiteName.length > 0 ? transaction.SiteName : ''}${transaction.DelcoName.length < 1 && transaction.SiteName.length < 1 ? '--' : ''}`">
                                {{(transaction.DelcoName != null && transaction.DelcoName.length > 0 && transaction.SiteName != null && transaction.SiteName.length > 0 ? transaction.DelcoName + ' - ' + transaction.SiteName : transaction.DelcoName != null && transaction.DelcoName.length > 0 ? transaction.DelcoName : transaction.SiteName != null && transaction.SiteName.length > 0 ? transaction.SiteName : '--').length < 30 ? transaction.DelcoName != null && transaction.DelcoName.length > 0 && transaction.SiteName != null && transaction.SiteName.length > 0 ? transaction.DelcoName + ' - ' + transaction.SiteName : transaction.DelcoName != null && transaction.DelcoName.length > 0 ? transaction.DelcoName : transaction.SiteName != null && transaction.SiteName.length > 0 ? transaction.SiteName : '--' : (transaction.DelcoName != null && transaction.DelcoName.length > 0 && transaction.SiteName != null && transaction.SiteName.length > 0 ? transaction.DelcoName + ' - ' + transaction.SiteName : transaction.DelcoName != null && transaction.DelcoName.length > 0 ? transaction.DelcoName : transaction.SiteName != null && transaction.SiteName.length > 0 ? transaction.SiteName : '--').substring(0,30) + '...'}}
                            </span>
                            <a v-if="transaction.Latitude != null && transaction.Latitude.length > 0 && transaction.Longitude != null && transaction.Longitude.length > 0" :href="`https://www.google.pl/maps/@${transaction.Latitude},${transaction.Longitude},16z`" target="_blank" class="transition ease-in-out duration-300 text-xxs ml-2 font-normal text-blue-400 hover:text-blue-500">Pokaż na mapie</a>
                            </div>
                        </td>
                        <td v-tooltip="`${transaction.ProductName.length > 0 ? transaction.ProductName : '--'}`" class="whitespace-nowrap px-2 py-2 text-sm text-slate-100"> <LoaderData v-if="!pricesLoaded" class=""></LoaderData> <span v-else>{{transaction.ProductName.length > 0 ? transaction.ProductName.length < 20 ? transaction.ProductName : transaction.ProductName.substring(0,20) + '...' : '--'}}</span> </td>
                        <td class="whitespace-nowrap px-2 py-2 text-sm text-slate-300"> {{transaction.Quantity}}</td>
                        <td class="whitespace-nowrap px-2 py-2 text-sm text-slate-300"> <LoaderData v-if="!pricesLoaded" class=""></LoaderData> <span v-else>{{transaction.WbTankGrossValue.toFixed(2)}} PLN</span></td>
                        <td class="whitespace-nowrap px-2 py-2 text-sm text-slate-100"> <LoaderData v-if="!pricesLoaded" class=""></LoaderData> <span v-else>{{transaction.WbTankNetValue.toFixed(2)}} PLN</span></td>
                        <td class="whitespace-nowrap px-2 py-2 text-sm text-slate-300"> {{translateDate(transaction.TransactionDate + ' ' + transaction.TransactionTime, 'dateWithHour')}} </td>
                        </tr>
                    </tbody>
                    </table>
                    <EmptyState v-if="!searchingTransactions && transactions === null"></EmptyState>
                </div>
                </div>
                <div v-if="searchingTransactions">
                        <LoadingSpinnerHub></LoadingSpinnerHub>
                </div>
            </div>
            <div v-if="!searchingTransactions && transactions !== null && transactions.length > hitsOnPage" class="w-full flex justify-center p-0.5 mt-2">
                    <span @click.prevent="addHits()" class="transition duration-300 ease-in-out px-2 py-1 text-sm shadow-lg bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer">Wczytaj więcej</span>
            </div>
            <div v-if="!searchingTransactions" class="w-full flex text-xs text-slate-400 justify-center mt-2">
                    Maksymalnie ostatnie 210 dni.
                </div>
            </div>

    </div>
  </div>
  <askFor v-if="blockAsk" @accept="blockCard" :modalData="modalDataBlock" @closeModal="blockAsk = false"></askFor>
  <askFor v-if="unlockAsk" @accept="unlockCard" :modalData="modalDataUnlock" @closeModal="unlockAsk = false"></askFor>
  <RestrictionsModal v-if="showRestrictionsModal" @closeModal="showRestrictionsModal = false"></RestrictionsModal>
</template>

<script>
import RestrictionsModal from '@/components/RestrictionsModal.vue';
import askFor from '@/components/AlertModal.vue';
import LoaderData from '../components/LoaderData.vue';
import { InformationCircleIcon, ChevronDownIcon,LockClosedIcon, ClockIcon, CreditCardIcon,LockOpenIcon, DocumentTextIcon } from "@heroicons/vue/outline"
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import EmptyState from '../components/EmptyState.vue';
import moment from 'moment';
import axios from 'axios';
import {db} from "@/firebase/gfbconf.js"
import currency from 'currency.js'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
export default {
    data()
        {
            return {
                loadedImg: false,
                pricesLoaded: false,
                orlenData: null,
                searchingCard: true,
                card: {},
                cardStatuses: [],
                transactions: null,
                hitsOnPage: 10,
                searchingTransactions: true,
                cardTemporaryBlockInfo: false,
                cardUnlockInfo: false,
                showRestrictionsModal: false,
                blockAsk: false,
                statementMonths: [],
                modalDataBlock: {
                    headerText: "Tymczasowe blokowanie karty",
                    bodyText: "Czy na pewno chcesz zablokować tymczasowo kartę? Nie będziesz mógł z niej od teraz korzystać.",
                    acceptButtonText: "Akceptuje"
                },
                unlockAsk: false,
                modalDataUnlock: {
                    headerText: "Odblokowanie karty",
                    bodyText: "Czy na pewno chcesz odblokować kartę? Może to zająć dłuższą chwilę.",
                    acceptButtonText: "Akceptuje"
                }
            }
        },
    components: {
        LoadingSpinnerHub,
        LoaderData,
        InformationCircleIcon,
        ChevronDownIcon,
        LockClosedIcon,
        ClockIcon,
        CreditCardIcon,
        DocumentTextIcon,
        LockOpenIcon,
        EmptyState,
        askFor,
        RestrictionsModal,
        Menu, MenuButton, MenuItem, MenuItems,

    },
    methods: {
        printStatement(month){
            let pickedMonthTransactions = []
            for (let i = 0; i < this.transactions.length; i++) {
                let crntTransacion = this.transactions[i]
                if(crntTransacion.month === month[1]){
                    pickedMonthTransactions.push(crntTransacion)
                }
            }
            let crntMonthYear = this.translateToYear(month[0])
            let today = moment().format('DD.MM.YYYY')

            let allNetValue = 0
            let allVATValue = 0
            let allGrossValue = 0
            
            let transactionsTable = [
                    [
                        {
                        text: 'Lp.',
                        border: [false, true, true, true],
                        alignment: 'center',
                        fillColor: '#eaf2f5',
                        margin: [-9, 10, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        },
                        {
                        text: 'Identyfikator transakcji',
                        border: [true, true, true, true],
                        alignment: 'center',
                        fillColor: '#eaf2f5',
                        margin: [-9, 10, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        },
                        {
                        text: 'Data transakcji',
                        fillColor: '#eaf2f5',
                        alignment: 'center',
                        border: [true, true, true, true],
                        margin: [-9, 10, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        },
                        {
                        text: 'Miejsce transakcji',
                        fillColor: '#eaf2f5',
                        alignment: 'center',
                        border: [true, true, true, true],
                        margin: [-9, 10, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        },
                        {
                        text: 'Produkt',
                        border: [true, true, true, true],
                        alignment: 'center',
                        fillColor: '#eaf2f5',
                        margin: [-9, 10, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        },
                        {
                        text: 'Ilość',
                        border: [true, true, true, true],
                        alignment: 'center',
                        fillColor: '#eaf2f5',
                        margin: [-9, 10, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        },
                        {
                        text: 'Wartość jedn. brutto',
                        border: [true, true, true, true],
                        alignment: 'center',
                        fillColor: '#eaf2f5',
                        margin: [-9, 5, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        },
                        {
                        text: 'Wartość netto',
                        border: [true, true, true, true],
                        alignment: 'center',
                        fillColor: '#eaf2f5',
                        margin: [-9, 10, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        },
                        {
                        text: 'Wartość VAT',
                        border: [true, true, true, true],
                        alignment: 'center',
                        fillColor: '#eaf2f5',
                        margin: [-9, 10, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        },
                        {
                        text: 'Wartość brutto',
                        border: [true, true, true, true],
                        alignment: 'center',
                        fillColor: '#eaf2f5',
                        margin: [-9, 10, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        }
                    ]
                    ]

                    for (let t = 0; t < pickedMonthTransactions.length; t++) {
                        let crntTransacion = pickedMonthTransactions[t];
                        allNetValue += crntTransacion.WbTankNetValue
                        allVATValue += crntTransacion.WbTankVATValue
                        allGrossValue += crntTransacion.WbTankGrossValue
                        allNetValue = Math.round(allNetValue * 100) / 100
                        allVATValue = Math.round(allVATValue * 100) / 100
                        allGrossValue = Math.round(allGrossValue * 100) / 100
                        transactionsTable.push(
                            [
                                {
                                text: `${t+1}`,
                                border: [false, true, true, true],
                                
                                margin: [-9, 5, -5, 5],
                                alignment: 'right',
                                fontSize: 8,
                                },
                                {
                                border: [true, true, true, true],
                                text: `${crntTransacion.TransactionId}`,
                                fillColor: '#f5f5f5',
                                margin: [-9, 5, -9, 5],
                                alignment: 'center',
                                fontSize: 8,
                                },
                                {
                                border: [true, true, true, true],
                                text: `${this.translateDate(crntTransacion.TransactionDate + ' ' + crntTransacion.TransactionTime, 'dateWithHour')}`,
                                fillColor: '#f5f5f5',
                                margin: [-9, 5, -9, 5],
                                alignment: 'center',
                                fontSize: 8,
                                },
                                {
                                border: [true, true, true, true],
                                text: `${crntTransacion.DelcoName.length > 0 ? crntTransacion.DelcoName : ''}${crntTransacion.DelcoName.length > 0 && crntTransacion.SiteName.length > 0 ? ' - ' : ''}${crntTransacion.SiteName.length > 0 ? crntTransacion.SiteName : ''}${crntTransacion.DelcoName.length < 1 && crntTransacion.SiteName.length < 1 ? '--' : ''}`,
                                fillColor: '#f5f5f5',
                                margin: [-9, 5, -9, 5],
                                alignment: 'center',
                                fontSize: 8,
                                },
                                {
                                border: [true, true, true, true],
                                text: `${crntTransacion.ProductName}`,
                                fillColor: '#f5f5f5',
                                margin: [-9, 5, -9, 5],
                                alignment: 'center',
                                fontSize: 8,
                                },
                                {
                                border: [true, true, true, true],
                                text: `${crntTransacion.Quantity.toFixed(2)}`,
                                fillColor: '#f5f5f5',
                                margin: [-9, 5, -9, 5],
                                alignment: 'center',
                                fontSize: 8,
                                },
                                {
                                border: [true, true, true, true],
                                text: `${crntTransacion.WbTankGrossUnitPrice.toFixed(2)} PLN`,
                                fillColor: '#f5f5f5',
                                margin: [-9, 5, -9, 5],
                                alignment: 'center',
                                fontSize: 8,
                                },
                                {
                                border: [true, true, true, true],
                                text: `${crntTransacion.WbTankNetValue.toFixed(2)} PLN`,
                                fillColor: '#f5f5f5',
                                margin: [-9, 5, -9, 5],
                                alignment: 'center',
                                fontSize: 8,
                                },
                                {
                                border: [true, true, true, true],
                                text: `${crntTransacion.WbTankVATValue.toFixed(2)} PLN`,
                                fillColor: '#f5f5f5',
                                margin: [-9, 5, -9, 5],
                                alignment: 'center',
                                fontSize: 8,
                                },
                                {
                                border: [true, true, true, true],
                                text: `${crntTransacion.WbTankGrossValue.toFixed(2)} PLN`,
                                fillColor: '#f5f5f5',
                                margin: [-9, 5, -9, 5],
                                alignment: 'center',
                                fontSize: 8,
                                }
                            ]
                        )
                    }

            let docDefinition = {
                pageSize: 'A4',
                pageOrientation: 'landscape',
            content: [
                {
                columns: [
                    [
                    {
                        text: 'Zestawienie miesięczne karty flota',
                        color: '#333333',
                        width: '*',
                        fontSize: 16,
                        bold: true,
                        alignment: 'center',
                        margin: [0, 10, 0, 10],
                    }
                    ],
                ],
                },
                {
                columns: [
                    {
                    text: 'Numer karty',
                    color: '#aaaaab',
                    bold: true,
                    fontSize: 12,
                    alignment: 'left',
                    margin: [0, 15, 0, 2],
                    },
                    {
                    text: 'Zestawienie dla miesiąca',
                    color: '#aaaaab',
                    bold: true,
                    fontSize: 12,
                    alignment: 'left',
                    margin: [0, 15, 0, 2],
                    }
                ],
                },
                {
                columns: [
                    {
                    text: `${this.addSpaces(pickedMonthTransactions[0].CardPAN)}`,
                    bold: true,
                    fontSize: 10,
                    color: '#333333',
                    alignment: 'left',
                    },
                    {
                    text: `${this.testTBC(month[1]) ? month[1] + ' ' + crntMonthYear + ' (do ' + today +')' : month[1] + ' ' + crntMonthYear}`,
                    bold: true,
                    fontSize: 10,
                    color: '#333333',
                    alignment: 'left',
                    }
                ],
                },
                '\n',
                {
                layout: {
                    defaultBorder: false,
                    hLineWidth: function(i, node) {
                    return 1;
                    },
                    vLineWidth: function(i, node) {
                    return 1;
                    },
                    hLineColor: function(i, node) {
                    if (i === 1 || i === 0) {
                        return '#bfdde8';
                    }
                    return '#eaeaea';
                    },
                    vLineColor: function(i, node) {
                    return '#eaeaea';
                    },
                    hLineStyle: function(i, node) {
                    // if (i === 0 || i === node.table.body.length) {
                    return null;
                    //}
                    },
                    // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                    paddingLeft: function(i, node) {
                    return 10;
                    },
                    paddingRight: function(i, node) {
                    return 10;
                    },
                    paddingTop: function(i, node) {
                    return 2;
                    },
                    paddingBottom: function(i, node) {
                    return 2;
                    },
                    fillColor: function(rowIndex, node, columnIndex) {
                    return '#fff';
                    },
                },
                table: {
                    headerRows: 1,
                    dontBreakRows: true,
                    widths: [1,75,85,120,'*',10,30,35,35,35],
                    body: transactionsTable,
                },
                },
                '\n',
                '\n',
                '\n',
                { stack:[
                {
                layout: {
                    defaultBorder: false,
                    hLineWidth: function(i, node) {
                    return 1;
                    },
                    vLineWidth: function(i, node) {
                    return 1;
                    },
                    hLineColor: function(i, node) {
                    return '#eaeaea';
                    },
                    vLineColor: function(i, node) {
                    return '#eaeaea';
                    },
                    hLineStyle: function(i, node) {
                    // if (i === 0 || i === node.table.body.length) {
                    return null;
                    //}
                    },
                    // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                    paddingLeft: function(i, node) {
                    return 10;
                    },
                    paddingRight: function(i, node) {
                    return 10;
                    },
                    paddingTop: function(i, node) {
                    return 3;
                    },
                    paddingBottom: function(i, node) {
                    return 3;
                    },
                    fillColor: function(rowIndex, node, columnIndex) {
                    return '#fff';
                    },
                },
                table: {
                    headerRows: 1,
                    dontBreakRows: true,
                    widths: ['66%', '34%'],
                    body: [
                    [
                        {
                        text: 'Wartość netto',
                        border: [false, true, false, true],
                        alignment: 'right',
                        margin: [0, 5, 0, 5],
                        },
                        {
                        border: [false, true, false, true],
                        text: `${allNetValue.toFixed(2)} PLN`,
                        alignment: 'right',
                        fillColor: '#f5f5f5',
                        margin: [0, 5, 0, 5],
                        },
                    ],
                    [
                        {
                        text: 'Wartość VAT',
                        border: [false, false, false, true],
                        alignment: 'right',
                        margin: [0, 5, 0, 5],
                        },
                        {
                        text: `${allVATValue.toFixed(2)} PLN`,
                        border: [false, false, false, true],
                        fillColor: '#f5f5f5',
                        alignment: 'right',
                        margin: [0, 5, 0, 5],
                        },
                    ],
                    [
                        {
                        text: 'Wartość brutto',
                        bold: true,
                        fontSize: 16,
                        alignment: 'right',
                        border: [false, false, false, true],
                        margin: [0, 5, 0, 5],
                        },
                        {
                        text: `${allGrossValue.toFixed(2)} PLN`,
                        bold: true,
                        fontSize: 16,
                        alignment: 'right',
                        border: [false, false, false, true],
                        fillColor: '#f5f5f5',
                        margin: [0, 5, 0, 5],
                        },
                    ],
                    ],
                },
                },
                ],
                unbreakable: true
                }
            ],
            styles: {
            },
            defaultStyle: {
                columnGap: 20,
                //font: 'Quicksand',
            },
            };

            pdfMake.createPdf(docDefinition).download(`Zestawienie_karty_${pickedMonthTransactions[0].CardPAN}_${month[1]}_${crntMonthYear}.pdf`);
        },
        testTBC(month){
            const crntMonth = moment().format('MMMM')
            let response = false
            if(month.toLowerCase() === crntMonth){
                response = true
            }
            return response
        },
        translateToYear(unix){
            let year = moment(unix, 'X').format('YYYY');
            return year
        },
        log(log){
            console.log(log)
        },
        async countPrices() {
          if(this.transactions !== null) {
            let feeProductsNames = (await db.collection("ProductTranslations").doc('feeProducts').get()).data()
            let res = await db.collection("ProductTranslations").doc('products').get()
            let productNames = res.data()
            let polandTransactions = {
                wbtank: {
                    adBlue: [],
                    diesel: [],
                    dieselVPower: [],
                    petrol95: [],
                    petrol95VPower: [],
                    petrol100Racing: [],
                    lpg: [],
                    others: []
                },
                economy: {
                    adBlue: [],
                    diesel: [],
                    dieselVPower: [],
                    petrol95: [],
                    petrol95VPower: [],
                    petrol100Racing: [],
                    lpg: [],
                    others: []
                },
                standard: {
                    adBlue: [],
                    diesel: [],
                    dieselVPower: [],
                    petrol95: [],
                    petrol95VPower: [],
                    petrol100Racing: [],
                    lpg: [],
                    others: []
                },
                highway: {
                    adBlue: [],
                    diesel: [],
                    dieselVPower: [],
                    petrol95: [],
                    petrol95VPower: [],
                    petrol100Racing: [],
                    lpg: [],
                    others: []
                },
                unassigned: {
                    others: []
                }
            }
            let internationalTransactions = []
            let currentUser = this.$store.state.userData
            let transactions = this.transactions
            transactions.forEach(transaction => {
                if(transaction.SiteGroupName === null){
                    transaction.SiteGroupName = 'UNASSIGNED'
                }
                
                transaction.SiteGroupName = transaction.SiteGroupName.toUpperCase()
                    if(transaction.PurchasedInCountryCode === "PL"){
                        //TRANSACTIONS ON WBTANK SITE
                        if(transaction.SiteCode === '8452'){
                            if((transaction.ProductName.toUpperCase()).includes('ADBLUE') && transaction.ProductCode === '38'){
                                let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                    currentUser.discounts.adBlue.wbtank.forEach(discount => {
                                    if(discount.dateTo === null){
                                    discount.dateTo = {}
                                    discount.dateTo.seconds = moment().unix()
                                    }
                                    if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                    transaction.assignedDiscount = discount.amount
                                    }
                                });
                                polandTransactions.wbtank.adBlue.push(transaction)
                            }
                            else if((transaction.ProductName.toUpperCase()).includes('DIESEL') && transaction.ProductCode === '30'){
                                let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                    currentUser.discounts.diesel.wbtank.forEach(discount => {
                                    if(discount.dateTo === null){
                                    discount.dateTo = {}
                                    discount.dateTo.seconds = moment().unix()
                                    }
                                    if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                    transaction.assignedDiscount = discount.amount
                                    transaction.assignedDiscountOrlenSpot = discount.orlenSpot
                                    }
                                });
                                polandTransactions.wbtank.diesel.push(transaction)
                            }
                            else if(transaction.ProductCode === '33'){
                                let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                currentUser.discounts.dieselVPower.wbtank.forEach(discount => {
                                if(discount.dateTo === null){
                                    discount.dateTo = {}
                                    discount.dateTo.seconds = moment().unix()
                                }
                                if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                        transaction.assignedDiscount = discount.amount
                                }
                                });
                                polandTransactions.wbtank.dieselVPower.push(transaction)
                            }
                            else if(transaction.ProductCode === '22'){
                                let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                currentUser.discounts.petrol95.wbtank.forEach(discount => {
                                if(discount.dateTo === null){
                                    discount.dateTo = {}
                                    discount.dateTo.seconds = moment().unix()
                                }
                                if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                        transaction.assignedDiscount = discount.amount
                                }
                                });
                                polandTransactions.wbtank.petrol95.push(transaction)
                            }
                            else if(transaction.ProductCode === '20' || transaction.ProductCode === '26'){
                                let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                currentUser.discounts.petrol95VPower.wbtank.forEach(discount => {
                                if(discount.dateTo === null){
                                    discount.dateTo = {}
                                    discount.dateTo.seconds = moment().unix()
                                }
                                if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                        transaction.assignedDiscount = discount.amount
                                }
                                });
                                polandTransactions.wbtank.petrol95VPower.push(transaction)
                            }
                            else if(transaction.ProductCode === '24' || transaction.ProductCode === '27'){
                                let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                currentUser.discounts.petrol100Racing.wbtank.forEach(discount => {
                                if(discount.dateTo === null){
                                    discount.dateTo = {}
                                    discount.dateTo.seconds = moment().unix()
                                }
                                if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                        transaction.assignedDiscount = discount.amount
                                }
                                });
                                polandTransactions.wbtank.petrol100Racing.push(transaction)
                            }
                            else if(transaction.ProductCode === '34'){
                                let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                currentUser.discounts.lpg.wbtank.forEach(discount => {
                                if(discount.dateTo === null){
                                    discount.dateTo = {}
                                    discount.dateTo.seconds = moment().unix()
                                }
                                if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                        transaction.assignedDiscount = discount.amount
                                }
                                });
                                polandTransactions.wbtank.lpg.push(transaction)
                            }
                            else{
                                polandTransactions.wbtank.others.push(transaction)
                            }
                        } // TRANSACTIONS ON ECONOMY SITE
                        else if(transaction.SiteGroupName.includes('ECONO')){
                            if((transaction.ProductName.toUpperCase()).includes('ADBLUE') && transaction.ProductCode === '38'){
                                let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                    currentUser.discounts.adBlue.economy.forEach(discount => {
                                    if(discount.dateTo === null){
                                    discount.dateTo = {}
                                    discount.dateTo.seconds = moment().unix()
                                    }
                                    if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                    transaction.assignedDiscount = discount.amount
                                    }
                                });
                                polandTransactions.economy.adBlue.push(transaction)
                            }
                            else if((transaction.ProductName.toUpperCase()).includes('DIESEL') && transaction.ProductCode === '30'){
                                let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                currentUser.discounts.diesel.economy.forEach(discount => {
                                    if(discount.dateTo === null){
                                    discount.dateTo = {}
                                    discount.dateTo.seconds = moment().unix()
                                    }
                                    if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                    transaction.assignedDiscount = discount.amount
                                    transaction.assignedDiscountOrlenSpot = discount.orlenSpot
                                    }
                                });
                                polandTransactions.economy.diesel.push(transaction)
                            }
                            else if(transaction.ProductCode === '33'){
                                let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                currentUser.discounts.dieselVPower.economy.forEach(discount => {
                                if(discount.dateTo === null){
                                    discount.dateTo = {}
                                    discount.dateTo.seconds = moment().unix()
                                }
                                if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                        transaction.assignedDiscount = discount.amount
                                }
                                });
                                polandTransactions.economy.dieselVPower.push(transaction)
                            }
                            else if(transaction.ProductCode === '22'){
                                let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                currentUser.discounts.petrol95.economy.forEach(discount => {
                                if(discount.dateTo === null){
                                    discount.dateTo = {}
                                    discount.dateTo.seconds = moment().unix()
                                }
                                if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                        transaction.assignedDiscount = discount.amount
                                }
                                });
                                polandTransactions.economy.petrol95.push(transaction)
                            }
                            else if(transaction.ProductCode === '20' || transaction.ProductCode === '26'){
                                let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                currentUser.discounts.petrol95VPower.economy.forEach(discount => {
                                if(discount.dateTo === null){
                                    discount.dateTo = {}
                                    discount.dateTo.seconds = moment().unix()
                                }
                                if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                        transaction.assignedDiscount = discount.amount
                                }
                                });
                                polandTransactions.economy.petrol95VPower.push(transaction)
                            }
                            else if(transaction.ProductCode === '24' || transaction.ProductCode === '27'){
                                let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                currentUser.discounts.petrol100Racing.economy.forEach(discount => {
                                if(discount.dateTo === null){
                                    discount.dateTo = {}
                                    discount.dateTo.seconds = moment().unix()
                                }
                                if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                        transaction.assignedDiscount = discount.amount
                                }
                                });
                                polandTransactions.economy.petrol100Racing.push(transaction)
                            }
                            else if(transaction.ProductCode === '34'){
                                let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                currentUser.discounts.lpg.economy.forEach(discount => {
                                if(discount.dateTo === null){
                                    discount.dateTo = {}
                                    discount.dateTo.seconds = moment().unix()
                                }
                                if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                        transaction.assignedDiscount = discount.amount
                                }
                                });
                                polandTransactions.economy.lpg.push(transaction)
                            }
                            else{
                                polandTransactions.economy.others.push(transaction)
                            }
                        } // TRANSACTIONS ON STANDARD SITE
                        else if(transaction.SiteGroupName.includes('DEFAUL') || transaction.SiteGroupName.includes('COVERA')){
                            if((transaction.ProductName.toUpperCase()).includes('ADBLUE') && transaction.ProductCode === '38'){
                                let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                currentUser.discounts.adBlue.standard.forEach(discount => {
                                    if(discount.dateTo === null){
                                        discount.dateTo = {}
                                        discount.dateTo.seconds = moment().unix()
                                    }
                                    if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                    transaction.assignedDiscount = discount.amount
                                    }
                                });
                                polandTransactions.standard.adBlue.push(transaction)
                            }
                            else if((transaction.ProductName.toUpperCase()).includes('DIESEL') && transaction.ProductCode === '30'){
                                let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                    currentUser.discounts.diesel.standard.forEach(discount => {
                                    if(discount.dateTo === null){
                                    discount.dateTo = {}
                                    discount.dateTo.seconds = moment().unix()
                                    }
                                    if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                    transaction.assignedDiscount = discount.amount
                                    transaction.assignedDiscountOrlenSpot = discount.orlenSpot
                                    }
                                });
                                polandTransactions.standard.diesel.push(transaction)
                            }
                            else if(transaction.ProductCode === '33'){
                                let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                currentUser.discounts.dieselVPower.standard.forEach(discount => {
                                if(discount.dateTo === null){
                                    discount.dateTo = {}
                                    discount.dateTo.seconds = moment().unix()
                                }
                                if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                        transaction.assignedDiscount = discount.amount
                                }
                                });
                                polandTransactions.standard.dieselVPower.push(transaction)
                            }
                            else if(transaction.ProductCode === '22'){
                                let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                currentUser.discounts.petrol95.standard.forEach(discount => {
                                if(discount.dateTo === null){
                                    discount.dateTo = {}
                                    discount.dateTo.seconds = moment().unix()
                                }
                                if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                        transaction.assignedDiscount = discount.amount
                                }
                                });
                                polandTransactions.standard.petrol95.push(transaction)
                            }
                            else if(transaction.ProductCode === '20' || transaction.ProductCode === '26'){
                                let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                currentUser.discounts.petrol95VPower.standard.forEach(discount => {
                                if(discount.dateTo === null){
                                    discount.dateTo = {}
                                    discount.dateTo.seconds = moment().unix()
                                }
                                if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                        transaction.assignedDiscount = discount.amount
                                }
                                });
                                polandTransactions.standard.petrol95VPower.push(transaction)
                            }
                            else if(transaction.ProductCode === '24' || transaction.ProductCode === '27'){
                                let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                currentUser.discounts.petrol100Racing.standard.forEach(discount => {
                                if(discount.dateTo === null){
                                    discount.dateTo = {}
                                    discount.dateTo.seconds = moment().unix()
                                }
                                if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                        transaction.assignedDiscount = discount.amount
                                }
                                });
                                polandTransactions.standard.petrol100Racing.push(transaction)
                            }
                            else if(transaction.ProductCode === '34'){
                                let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                currentUser.discounts.lpg.standard.forEach(discount => {
                                if(discount.dateTo === null){
                                    discount.dateTo = {}
                                    discount.dateTo.seconds = moment().unix()
                                }
                                if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                        transaction.assignedDiscount = discount.amount
                                }
                                });
                                polandTransactions.standard.lpg.push(transaction)
                            }
                            else{
                                polandTransactions.standard.others.push(transaction)
                            }
                        } // TRANSACTIONS ON MOTORWAY
                        else if(transaction.SiteGroupName.includes('MOTORWA')){
                            // polandTransactions.highway.others.push(transaction)
                            if((transaction.ProductName.toUpperCase()).includes('ADBLUE') && transaction.ProductCode === '38'){
                                let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                currentUser.discounts.adBlue.highway.forEach(discount => {
                                if(discount.dateTo === null){
                                        discount.dateTo = {}
                                        discount.dateTo.seconds = moment().unix()
                                }
                                if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                    transaction.assignedDiscount = discount.amount
                                }
                                });
                                polandTransactions.highway.adBlue.push(transaction)
                            }
                            else if((transaction.ProductName.toUpperCase()).includes('DIESEL') && transaction.ProductCode === '30'){
                                let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                currentUser.discounts.diesel.highway.forEach(discount => {
                                if(discount.dateTo === null){
                                    discount.dateTo = {}
                                    discount.dateTo.seconds = moment().unix()
                                }
                                if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                    transaction.assignedDiscount = discount.amount
                                    transaction.assignedDiscountOrlenSpot = discount.orlenSpot
                                }
                                });
                                polandTransactions.highway.diesel.push(transaction)
                            }
                            else if(transaction.ProductCode === '33'){
                            let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                currentUser.discounts.dieselVPower.highway.forEach(discount => {
                                if(discount.dateTo === null){
                                discount.dateTo = {}
                                discount.dateTo.seconds = moment().unix()
                                }
                                if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                    transaction.assignedDiscount = discount.amount
                                }
                            });
                            polandTransactions.highway.dieselVPower.push(transaction)
                            }
                            else if(transaction.ProductCode === '22'){
                            let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                currentUser.discounts.petrol95.highway.forEach(discount => {
                                if(discount.dateTo === null){
                                discount.dateTo = {}
                                discount.dateTo.seconds = moment().unix()
                                }
                                if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                    transaction.assignedDiscount = discount.amount
                                }
                            });
                            polandTransactions.highway.petrol95.push(transaction)
                            }
                            else if(transaction.ProductCode === '20' || transaction.ProductCode === '26'){
                            let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                currentUser.discounts.petrol95VPower.highway.forEach(discount => {
                                if(discount.dateTo === null){
                                discount.dateTo = {}
                                discount.dateTo.seconds = moment().unix()
                                }
                                if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                    transaction.assignedDiscount = discount.amount
                                }
                            });
                            polandTransactions.highway.petrol95VPower.push(transaction)
                            }
                            else if(transaction.ProductCode === '24' || transaction.ProductCode === '27'){
                            let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                currentUser.discounts.petrol100Racing.highway.forEach(discount => {
                                if(discount.dateTo === null){
                                discount.dateTo = {}
                                discount.dateTo.seconds = moment().unix()
                                }
                                if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                    transaction.assignedDiscount = discount.amount
                                }
                            });
                            polandTransactions.highway.petrol100Racing.push(transaction)
                            }
                            else if(transaction.ProductCode === '34'){
                            let transactionTime = moment(transaction.TransactionDate, "YYYYMMDD").unix()+60
                                currentUser.discounts.lpg.highway.forEach(discount => {
                                if(discount.dateTo === null){
                                discount.dateTo = {}
                                discount.dateTo.seconds = moment().unix()
                                }
                                if(transactionTime > discount.dateFrom.seconds && transactionTime < discount.dateTo.seconds){
                                    transaction.assignedDiscount = discount.amount
                                }
                            });
                            polandTransactions.highway.lpg.push(transaction)
                            }
                            else{
                                polandTransactions.highway.others.push(transaction)
                            }
                        }else{
                            polandTransactions.unassigned.others.push(transaction)
                        }

                    }else{
                        // IF INTERNATIONAL TRANSACTION assign VAT rate for invoice and VAT rate from transaction
                        transaction.VATRateFromTransaction = parseFloat(transaction.VATRate)
                        transaction.VATRate = parseFloat(0.23)
                        internationalTransactions.push(transaction)
                    }

                if(transaction.Type !== 'SALE') {
                  let feeProductTranslation = null
                  
                  let countryCode = transaction.ProductName.slice(16, 18)
                  let feeProductId = parseInt(transaction.ProductName.slice(18, 22))
                  let feeProductCode = parseInt(transaction.ProductName.slice(22, 24))
                  transaction.Quantity = 1

                  if(feeProductsNames[countryCode] != null) {
                    for(let j = 0; j < Object.values(feeProductsNames[countryCode]).length; j++) {
                        let feeProduct = Object.values(feeProductsNames[countryCode])[j]
                        if(feeProduct.shellId == feeProductId) {
                          for(let k = 0; k < feeProduct.shellCode.length; k++) {
                            let feeProductItem = feeProduct.shellCode[k]
                            if(feeProductItem == feeProductCode) {
                              feeProductTranslation = feeProduct.wbtankName
                              break
                            }
                          }
                        }
                    }
                  }
                  if(feeProductTranslation != null) transaction.ProductName = feeProductTranslation
                } else if(productNames[transaction.ProductCode] !== undefined){
                  transaction.ProductName = productNames[transaction.ProductCode].wbtankName
                }
                if(transaction.DelcoName === 'Shell Polska sp. z o.o.'){
                    transaction.DelcoName = 'Shell Polska'
                }
            });

            for (let pT = 0; pT < Object.keys(polandTransactions).length; pT++) {
                const typeOfStation = Object.keys(polandTransactions)[pT];
                for (let pN = 0; pN < Object.keys(polandTransactions[typeOfStation]).length; pN++) {
                const productName = Object.keys(polandTransactions[typeOfStation])[pN];
                polandTransactions[typeOfStation][productName].forEach(transaction => {
                    
                    // INVOICE DATA
                    let grossUnitPrice = null
                    let grossValue = null
                    let netValue = null
                    let netUnitPrice = null
                    let vatValue = null

                    // FOR DIESEL TRANSACTIONS WHEN ORLEN
                    let orlenDieselSpotPrice = null
                    let roundedOrlenDieselSpotPrice = null
                    let readyTransactionDate = null
                    let isBefore = null

                    if(productName === 'diesel'){
                    // tutaj koniec - przgotować pod kazdy rodzaj transakcji, przygotować discounty pod false itd...
                    orlenDieselSpotPrice = 0
                    readyTransactionDate = moment(transaction.TransactionDate, "YYYYMMDD").format("YYYY-MM-DD")
                    isBefore = moment(readyTransactionDate).isBetween(this.orlenData[0].effectiveDate)
                    if(isBefore){
                        orlenDieselSpotPrice = parseFloat(this.orlenData[0].value) / 1000
                    }else{
                        for (let oD = 0; oD < this.orlenData.length; oD++) {
                        if(this.orlenData[oD+1] !== undefined){
                        let first = this.orlenData[oD]
                        let second = this.orlenData[oD+1]
                        let firstDataDate = moment(first.effectiveDate).format("YYYY-MM-DD")
                        let secondDataDate = moment(second.effectiveDate).format("YYYY-MM-DD")
                        let isBetween = moment(readyTransactionDate).isBetween(secondDataDate, firstDataDate, undefined, '(]')
                        if(isBetween){
                            if(readyTransactionDate === firstDataDate){
                            orlenDieselSpotPrice = parseFloat(first.value) / 1000
                            }else{
                            orlenDieselSpotPrice = parseFloat(second.value) / 1000
                            }
                        }
                        }
                        }
                    }
                    roundedOrlenDieselSpotPrice = currency(orlenDieselSpotPrice).value

                    // if orlenSpot true
                    // ORLEN 0% - false
                    // ORLEN +2% - null
                    // ORLEN - X = from -0.3 to -0.01
                    // ORLEN + X = from 0.01 to 0.05
                    // else
                    // SŁUP 0% - false
                    // SŁUP  +2% - null
                    // SŁUP - X = from -0.3 to -0.01
                    // SŁUP + X = from 0.01 to 0.05

                    if(transaction.DelcoRetailPriceUnitNet !== undefined && transaction.DelcoRetailPriceUnitNet !== null && parseFloat(transaction.DelcoRetailPriceUnitNet) !== 0)
                    {
                        if(transaction.assignedDiscount !== undefined){
                        if(transaction.assignedDiscountOrlenSpot === undefined){
                            if(transaction.assignedDiscount === false){
                            // SŁUP 0%
                            grossUnitPrice = currency(parseFloat(transaction.DelcoRetailPriceUnitNet) * (parseFloat(transaction.VATRate) + 1)).value
                            }else if(typeof transaction.assignedDiscount === 'string' && transaction.assignedDiscount.includes('%')){
                            // SŁUP +2%
                            let discountInPercentAsNumber = 1
                                  if(transaction.assignedDiscount.includes('-')){
                                    //remove - and %
                                    discountInPercentAsNumber = 1 - (parseFloat(transaction.assignedDiscount.replace('-', '').replace('%', '')) / 100)
                                  }else{
                                    discountInPercentAsNumber = (parseFloat(transaction.assignedDiscount.replace('%', '')) / 100) + 1
                                  }
                            grossUnitPrice = currency((parseFloat(transaction.DelcoRetailPriceUnitNet) * (parseFloat(transaction.VATRate) + 1)) * discountInPercentAsNumber).value
                            }else{
                            // ORLEN 0% - 0
                            // ORLEN - X = od -0.3 do -0.01
                            // ORLEN + X = od 0.01 do 0.05
                            grossUnitPrice = currency((roundedOrlenDieselSpotPrice + transaction.assignedDiscount) * (parseFloat(transaction.VATRate) + 1)).value
                            }
                        }else if(transaction.assignedDiscountOrlenSpot === true){
                            if(transaction.assignedDiscount === false){
                            // orlen 0%
                            grossUnitPrice = currency((parseFloat(roundedOrlenDieselSpotPrice) * (parseFloat(transaction.VATRate) + 1))).value
                            // grossUnitPrice = Math.round(((roundedOrlenDieselSpotPrice) * (parseFloat(transaction.VATRate) + 1)) * 100) / 100 
                            }else if(typeof transaction.assignedDiscount === 'string' && transaction.assignedDiscount.includes('%')){
                            // orlen +2%
                            let discountInPercentAsNumber = 1
                                  if(transaction.assignedDiscount.includes('-')){
                                    //remove - and %
                                    discountInPercentAsNumber = 1 - (parseFloat(transaction.assignedDiscount.replace('-', '').replace('%', '')) / 100)
                                  }else{
                                    discountInPercentAsNumber = (parseFloat(transaction.assignedDiscount.replace('%', '')) / 100) + 1
                                  }
                            grossUnitPrice = currency((parseFloat(roundedOrlenDieselSpotPrice) * (parseFloat(transaction.VATRate) + 1)) * discountInPercentAsNumber).value
                            }else{
                            // ORLEN - X = od -0.3 do -0.01
                            // ORLEN + X = od 0.01 do 0.05
                            grossUnitPrice = currency(((roundedOrlenDieselSpotPrice + transaction.assignedDiscount) * (parseFloat(transaction.VATRate) + 1))).value
                            }
                        }else if(transaction.assignedDiscountOrlenSpot === false){
                            if(transaction.assignedDiscount === false){
                            // SŁUP 0%
                            grossUnitPrice = currency((parseFloat(transaction.DelcoRetailPriceUnitNet) * (parseFloat(transaction.VATRate) + 1))).value
                            }else if(typeof transaction.assignedDiscount === 'string' && transaction.assignedDiscount.includes('%')){
                            // SŁUP +2%
                            let discountInPercentAsNumber = 1
                                  if(transaction.assignedDiscount.includes('-')){
                                    //remove - and %
                                    discountInPercentAsNumber = 1 - (parseFloat(transaction.assignedDiscount.replace('-', '').replace('%', '')) / 100)
                                  }else{
                                    discountInPercentAsNumber = (parseFloat(transaction.assignedDiscount.replace('%', '')) / 100) + 1
                                  }
                            grossUnitPrice = currency((parseFloat(transaction.DelcoRetailPriceUnitNet) * (parseFloat(transaction.VATRate) + 1)) * discountInPercentAsNumber).value
                            }else{
                            // SŁUP - X = od -0.3 do -0.01
                            // SŁUP + X = od 0.01 do 0.05
                            grossUnitPrice = currency(parseFloat(transaction.DelcoRetailPriceUnitNet) * (parseFloat(transaction.VATRate) + 1) + transaction.assignedDiscount).value
                            }
                        }
                        }else{
                        grossUnitPrice = currency((parseFloat(transaction.DelcoRetailPriceUnitNet) * (parseFloat(transaction.VATRate) + 1)) * 1.02).value
                        }
                    }else{
                        if(transaction.assignedDiscount !== undefined){
                        if(transaction.assignedDiscountOrlenSpot === undefined){
                            if(transaction.assignedDiscount === false){
                            // SŁUP 0%
                            grossUnitPrice = currency((parseFloat(transaction.UnitPriceInTransactionCurrency)) * (parseFloat(transaction.VATRate) + 1)).value
                            }else if(typeof transaction.assignedDiscount === 'string' && transaction.assignedDiscount.includes('%')){
                            // SŁUP +2%
                            let discountInPercentAsNumber = 1
                                  if(transaction.assignedDiscount.includes('-')){
                                    //remove - and %
                                    discountInPercentAsNumber = 1 - (parseFloat(transaction.assignedDiscount.replace('-', '').replace('%', '')) / 100)
                                  }else{
                                    discountInPercentAsNumber = (parseFloat(transaction.assignedDiscount.replace('%', '')) / 100) + 1
                                  }
                            grossUnitPrice = currency((parseFloat(transaction.UnitPriceInTransactionCurrency) * discountInPercentAsNumber) * (parseFloat(transaction.VATRate) + 1)).value
                            }else{
                            // ORLEN 0% - 0
                            // ORLEN - X = od -0.3 do -0.01
                            // ORLEN + X = od 0.01 do 0.05
                            grossUnitPrice = currency((roundedOrlenDieselSpotPrice + transaction.assignedDiscount) * (parseFloat(transaction.VATRate) + 1)).value
                            }
                        }else if(transaction.assignedDiscountOrlenSpot === true){
                            if(transaction.assignedDiscount === false){
                            // ORLEN 0%
                            grossUnitPrice = currency((parseFloat(roundedOrlenDieselSpotPrice)) * (parseFloat(transaction.VATRate) + 1)).value
                            }else if(typeof transaction.assignedDiscount === 'string' && transaction.assignedDiscount.includes('%')){
                            // ORLEN +2%
                            let discountInPercentAsNumber = 1
                                  if(transaction.assignedDiscount.includes('-')){
                                    //remove - and %
                                    discountInPercentAsNumber = 1 - (parseFloat(transaction.assignedDiscount.replace('-', '').replace('%', '')) / 100)
                                  }else{
                                    discountInPercentAsNumber = (parseFloat(transaction.assignedDiscount.replace('%', '')) / 100) + 1
                                  }
                            grossUnitPrice = currency((parseFloat(roundedOrlenDieselSpotPrice) * discountInPercentAsNumber) * (parseFloat(transaction.VATRate) + 1)).value
                            }else{
                            // ORLEN - X = od -0.3 do -0.01
                            // ORLEN + X = od 0.01 do 0.05
                            grossUnitPrice = currency((roundedOrlenDieselSpotPrice + transaction.assignedDiscount) * (parseFloat(transaction.VATRate) + 1)).value
                            }
                        }else if(transaction.assignedDiscountOrlenSpot === false){
                            if(transaction.assignedDiscount === false){
                            // SŁUP 0%
                            grossUnitPrice = currency((parseFloat(transaction.UnitPriceInTransactionCurrency)) * (parseFloat(transaction.VATRate) + 1)).value
                            }else if(typeof transaction.assignedDiscount === 'string' && transaction.assignedDiscount.includes('%')){
                            // SŁUP +2%
                            let discountInPercentAsNumber = 1
                                  if(transaction.assignedDiscount.includes('-')){
                                    //remove - and %
                                    discountInPercentAsNumber = 1 - (parseFloat(transaction.assignedDiscount.replace('-', '').replace('%', '')) / 100)
                                  }else{
                                    discountInPercentAsNumber = (parseFloat(transaction.assignedDiscount.replace('%', '')) / 100) + 1
                                  }
                            grossUnitPrice = currency((parseFloat(transaction.UnitPriceInTransactionCurrency) * discountInPercentAsNumber) * (parseFloat(transaction.VATRate) + 1)).value
                            }else{
                            // SŁUP - X = od -0.3 do -0.01
                            // SŁUP + X = od 0.01 do 0.05
                            grossUnitPrice = currency(parseFloat(transaction.UnitPriceInTransactionCurrency) * (parseFloat(transaction.VATRate) + 1) + transaction.assignedDiscount).value
                            }
                        }
                        }else{
                        grossUnitPrice = currency((parseFloat(transaction.UnitPriceInTransactionCurrency) * 1.02) * (parseFloat(transaction.VATRate) + 1)).value
                        }
                    }
                    }else if(productName === 'adBlue'){
                    // SŁUP 0% - false
                    // SŁUP +2% - null
                    // SŁUP - X - from 0.1 to 3.41
                    if(transaction.DelcoListPriceUnitNet !== undefined && transaction.DelcoListPriceUnitNet !== null && parseFloat(transaction.DelcoListPriceUnitNet) !== 0){
                        if(transaction.assignedDiscount !== undefined){
                        if(transaction.assignedDiscount === false){
                            // SŁUP 0%
                            grossUnitPrice = currency(parseFloat(transaction.DelcoListPriceUnitNet) * (parseFloat(transaction.VATRate) + 1)).value
                        }else if(typeof transaction.assignedDiscount === 'string' && transaction.assignedDiscount.includes('%')){
                            // SŁUP +2%
                            let discountInPercentAsNumber = 1
                                  if(transaction.assignedDiscount.includes('-')){
                                    //remove - and %
                                    discountInPercentAsNumber = 1 - (parseFloat(transaction.assignedDiscount.replace('-', '').replace('%', '')) / 100)
                                  }else{
                                    discountInPercentAsNumber = (parseFloat(transaction.assignedDiscount.replace('%', '')) / 100) + 1
                                  }
                            grossUnitPrice = currency((parseFloat(transaction.DelcoListPriceUnitNet) * (parseFloat(transaction.VATRate) + 1)) * discountInPercentAsNumber).value
                        }else{
                            // SŁUP - X (x = discount)
                            grossUnitPrice = currency((parseFloat(transaction.DelcoListPriceUnitNet) * (parseFloat(transaction.VATRate) + 1)) - transaction.assignedDiscount).value
                            // jeśli brutto + 2% - rabat:
                            // grossUnitPrice = Math.round(((Math.round((parseFloat(transaction.DelcoListPriceUnitNet) * (parseFloat(transaction.VATRate) + 1)) * 100) / 100 - transaction.assignedDiscount) * 1.02) * 100) / 100
                        }
                        }else{
                        grossUnitPrice = currency((parseFloat(transaction.DelcoListPriceUnitNet) * (parseFloat(transaction.VATRate) + 1)) * 1.02).value
                        }
                    }else{
                        grossUnitPrice = currency((transaction.assignedDiscount !== undefined ? (transaction.UnitPriceInTransactionCurrency - transaction.assignedDiscount) * (parseFloat(transaction.VATRate) + 1)  : (parseFloat(transaction.UnitPriceInTransactionCurrency) * 1.02) * (parseFloat(transaction.VATRate) + 1))).value
                    }
                    }else if(productName === 'others'){
                    if(transaction.DelcoRetailPriceUnitNet !== undefined && transaction.DelcoRetailPriceUnitNet !== null && parseFloat(transaction.DelcoRetailPriceUnitNet) !== 0){
                        grossUnitPrice = currency((parseFloat(transaction.DelcoRetailPriceUnitNet) * (parseFloat(transaction.VATRate) + 1)) * 1.02).value
                    }else{
                        grossUnitPrice = currency((parseFloat(transaction.UnitPriceInTransactionCurrency) * 1.02) * (parseFloat(transaction.VATRate) + 1)).value
                    }
                    }else{
                    // PRODUCTS: dieselVPower, petrol95, petrol95VPower, petrol100Racing, lpg
                    // SŁUP 0% - false
                    // SŁUP +2% - null
                    // SŁUP - X = from -0.3 to -0.01
                    // SŁUP + X = from 0.01 to 0.05
                    if(transaction.DelcoRetailPriceUnitNet !== undefined && transaction.DelcoRetailPriceUnitNet !== null && parseFloat(transaction.DelcoRetailPriceUnitNet) !== 0){
                        if(transaction.assignedDiscount !== undefined){
                        if(transaction.assignedDiscount === false){
                            // SŁUP 0%
                            grossUnitPrice = currency((parseFloat(transaction.DelcoRetailPriceUnitNet) * (parseFloat(transaction.VATRate) + 1))).value
                        }else if(typeof transaction.assignedDiscount === 'string' && transaction.assignedDiscount.includes('%')){
                            // SŁUP +2%
                            let discountInPercentAsNumber = 1
                                  if(transaction.assignedDiscount.includes('-')){
                                    //remove - and %
                                    discountInPercentAsNumber = 1 - (parseFloat(transaction.assignedDiscount.replace('-', '').replace('%', '')) / 100)
                                  }else{
                                    discountInPercentAsNumber = (parseFloat(transaction.assignedDiscount.replace('%', '')) / 100) + 1
                                  }
                            grossUnitPrice = currency((parseFloat(transaction.DelcoRetailPriceUnitNet) * (parseFloat(transaction.VATRate) + 1)) * discountInPercentAsNumber).value
                        }else{
                            // SŁUP - X (x = discount)
                            grossUnitPrice = currency((parseFloat(transaction.DelcoRetailPriceUnitNet) * (parseFloat(transaction.VATRate) + 1) + transaction.assignedDiscount)).value
                        }
                        }else{
                        grossUnitPrice = currency((parseFloat(transaction.DelcoRetailPriceUnitNet) * (parseFloat(transaction.VATRate) + 1)) * 1.02).value
                        }
                    }else{
                        grossUnitPrice = currency((transaction.assignedDiscount !== undefined ? (transaction.UnitPriceInTransactionCurrency - transaction.assignedDiscount) * (parseFloat(transaction.VATRate) + 1)  : (parseFloat(transaction.UnitPriceInTransactionCurrency) * 1.02) * (parseFloat(transaction.VATRate) + 1))).value
                        if(transaction.assignedDiscount !== undefined){
                        if(transaction.assignedDiscount === false){
                            // SŁUP 0%
                            grossUnitPrice = currency(parseFloat(transaction.UnitPriceInTransactionCurrency) * (parseFloat(transaction.VATRate) + 1)).value
                        }else if(typeof transaction.assignedDiscount === 'string' && transaction.assignedDiscount.includes('%')){
                            // SŁUP +2%
                            let discountInPercentAsNumber = 1
                                  if(transaction.assignedDiscount.includes('-')){
                                    //remove - and %
                                    discountInPercentAsNumber = 1 - (parseFloat(transaction.assignedDiscount.replace('-', '').replace('%', '')) / 100)
                                  }else{
                                    discountInPercentAsNumber = (parseFloat(transaction.assignedDiscount.replace('%', '')) / 100) + 1
                                  }
                            grossUnitPrice = currency((parseFloat(transaction.UnitPriceInTransactionCurrency) * discountInPercentAsNumber) * (parseFloat(transaction.VATRate) + 1)).value
                        }else{
                            // SŁUP - X = from -0.3 to -0.01
                            // SŁUP + X = from 0.01 to 0.05
                            grossUnitPrice = currency(transaction.UnitPriceInTransactionCurrency * (parseFloat(transaction.VATRate) + 1) + transaction.assignedDiscount).value
                        }
                        }else{
                        grossUnitPrice = currency((parseFloat(transaction.UnitPriceInTransactionCurrency) * 1.02) * (parseFloat(transaction.VATRate) + 1)).value
                        }
                    }
                    }


                    // V2.1 - FIXED IF VAT FOR OWNER - TESTING (added real vatratefromtransaction)
                    if(transaction.Type !== 'SALE') {
                        let unitPrice = currency((parseFloat(transaction.TransactionNetAmount) * (parseFloat(transaction.VATRate) + 1)) * 1.02).value
                        grossValue = unitPrice
                        netValue = transaction.TransactionNetAmount
                        netUnitPrice = netValue
                        vatValue = currency(grossValue - netValue).value
                    } else {
                        grossValue = currency(grossUnitPrice * parseFloat(transaction.Quantity)).value
                        netValue = currency(grossValue / (parseFloat(transaction.VATRate) * 100 + 100) * 100).value
                        netUnitPrice = currency(netValue / parseFloat(transaction.Quantity)).value
                        vatValue = currency(grossValue - netValue).value
                    }
                    // count values based on grossUnitPrice
                    transaction.WbTankGrossValue = grossValue
                    transaction.WbTankNetValue = netValue
                    transaction.WbTankVATValue = currency(grossValue - netValue).value
                    transaction.WbTankNetUnitPrice = netUnitPrice
                    transaction.WbTankGrossUnitPrice = grossUnitPrice


                });
                }
            }

            internationalTransactions.forEach(transaction => {
                // V1 - WRONG  
                // let grossUnitPrice = transaction.TransactionCurrencyCode !== 'PLN' ? Math.round(((parseFloat(transaction.UnitPriceInTransactionCurrency) * parseFloat(transaction.ColCoExchangeRate) * 1.02) * (parseFloat(transaction.VATRate) + 1)) * 100) / 100 : Math.round(((parseFloat(transaction.UnitPriceInTransactionCurrency) * 1.02) * (parseFloat(transaction.VATRate) + 1)) * 100) / 100
                
                // V2 - WRONG IF VAT FOR OWNER
                // let grossUnitPrice = transaction.TransactionCurrencyCode !== 'PLN' ? Math.round(((parseFloat(transaction.UnitPriceInInvoiceCurrency) * 1.02) * 1.23) * 100) / 100 : Math.round(((parseFloat(transaction.UnitPriceInTransactionCurrency) * 1.02) * (parseFloat(transaction.VATRate) + 1)) * 100) / 100
                
                // V3 - WRONG IF CZK / FINE FOR EUR ONLY (?)
                // let grossUnitPrice = transaction.TransactionCurrencyCode !== 'PLN' ? Math.round((((parseFloat(transaction.TransactionGrossAmount) / parseFloat(transaction.Quantity)) * parseFloat(transaction.ColCoExchangeRate)  * 1.02) * 1.23) * 100) / 100 : Math.round(((parseFloat(transaction.UnitPriceInTransactionCurrency) * 1.02) * (parseFloat(transaction.VATRate) + 1)) * 100) / 100
                
                let grossUnitPrice = 0
                let grossValue = 0
                let netValue = 0
                let netUnitPrice = 0
                let vatValue = 0

                // V2.1 - FIXED IF VAT FOR OWNER - TESTING (added real vatratefromtransaction)
                if(transaction.Type !== 'SALE') {
                    grossUnitPrice = currency((parseFloat(transaction.TransactionNetAmount) * (parseFloat(transaction.VATRate) + 1)) * 1.02).value
                    grossValue = grossUnitPrice
                    netValue = transaction.TransactionNetAmount
                    netUnitPrice = netValue
                    vatValue = currency(grossValue - netValue).value
                } else {
                    grossUnitPrice = transaction.TransactionCurrencyCode !== 'PLN' ? currency((parseFloat(transaction.UnitPriceInInvoiceCurrency) * (parseFloat(transaction.VATRateFromTransaction) + 1) * 1.02) * 1.23).value : currency((parseFloat(transaction.UnitPriceInTransactionCurrency) * 1.02) * (parseFloat(transaction.VATRate) + 1)).value
                    grossValue = currency(grossUnitPrice * parseFloat(transaction.Quantity)).value
                    netValue = currency(grossValue / (parseFloat(transaction.VATRate) * 100 + 100) * 100).value
                    netUnitPrice = currency(netValue / parseFloat(transaction.Quantity)).value
                    vatValue = currency(grossValue - netValue).value
                }

                transaction.WbTankGrossValue = grossValue  
                transaction.WbTankNetValue = netValue
                transaction.WbTankVATValue = currency(grossValue - netValue).value
                transaction.WbTankNetUnitPrice = netUnitPrice
                transaction.WbTankGrossUnitPrice = grossUnitPrice
            });
            this.pricesLoaded = true
            this.loaded = true   
          }
        },
        async blockCard(){
            if(this.card.TemporaryBlockAllowed){
                this.$store.commit('setGlobalLoader',{
                    show: true,
                    head: "Blokuje kartę..",
                    subheader: `Nie zamykaj tego okna.`,
                });
                const res = await axios.post("https://europe-central2-lauron-a8b59.cloudfunctions.net/api/temporaryBlockCard", {
                    cardId: `${this.$route.query.cid}`
                })
                if(res.data.success){
                        this.$store.commit("resetGlobalLoader");
                        await this.findCard()
                        this.cardTemporaryBlockInfo = true
                }else{
                    this.$store.commit("resetGlobalLoader");
                    if(res.data.block.Errors[0].Code === 'E0001'){
                        alert('Karta jest już w trakcie blokowywania.')
                        await this.findCard()
                    }else{
                        alert('Nie udało się zablokować karty')
                    }
                }
            }
        },
        async unlockCard(){
            if(this.card.UnblockAllowed){
                this.$store.commit('setGlobalLoader',{
                    show: true,
                    head: "Odblokowuję kartę..",
                    subheader: `Nie zamykaj tego okna.`,
                });
                const res = await axios.post("https://europe-central2-lauron-a8b59.cloudfunctions.net/api/unlockCard", {
                    cardId: `${this.$route.query.cid}`
                })
                if(res.data.success){
                        this.$store.commit("resetGlobalLoader");
                        await this.findCard()
                        this.cardUnlockInfo = true
                }else{
                    this.$store.commit("resetGlobalLoader");
                    if(res.data.block.Errors[0].Code === 'E0001'){
                        alert('Karta jest już w trakcie odblokowywania.')
                        await this.findCard()
                        this.cardUnlockInfo = true
                    }else{
                        alert('Nie udało się odblokować karty')
                    }
                }
            }
        },
        addHits(){
            if(this.transactions.length - 10 < this.hitsOnPage){
                this.hitsOnPage = this.transactions.length
            }else{
                this.hitsOnPage += 10
            }
        },
        sortTransactions()
            {
              this.transactions.sort((a,b)=>
              {
                if(a.TransactionDateUnix > b.TransactionDateUnix) return 1;
                if(a.TransactionDateUnix < b.TransactionDateUnix) return -1;
                return 0;
              })
              this.transactions.reverse();
            },
        async findTransactions(){
            this.searchingTransactions = true
            this.transactions = null
            this.orlenData = null
            const res = await axios.post("https://europe-central2-lauron-a8b59.cloudfunctions.net/api/getCardFeed", {
            // const res = await axios.post("http://127.0.0.1:5001/lauron-a8b59/europe-central2/api/getCardFeed", {
                cardId: `${this.$route.query.cid}`,
                groupOfCardsId: this.$store.state.userData.groupOfCardsId
            })
            if(res.data.success){
            this.transactions = res.data.transactions
            if(this.transactions !== null){
            this.orlenData = res.data.orlenData

            let months = {}
            let sortableMonths = []
            this.transactions.forEach((transaction, index) => {
                transaction.TransactionDateUnix = moment(transaction.TransactionDate + ' ' + transaction.TransactionTime, "YYYYMMDD h:mm:ss").unix()
                transaction.month = moment(transaction.TransactionDate, "YYYYMMDD").format('MMMM')
                transaction.month = transaction.month.charAt(0).toUpperCase() + transaction.month.slice(1)
                transaction.monthSort = moment(transaction.TransactionDate, "YYYYMMDD").format("YYYYMM")
                transaction.monthSort = moment(transaction.monthSort, "YYYYMM").unix()
                transaction.WbTankGrossValue = '-'
                transaction.WbTankNetValue = '-'
                transaction.WbTankVATValue = '-'
                if(months[transaction.month] === undefined){
                    months[transaction.month] = transaction.monthSort
                }
            });
            let monthsByName = Object.keys(months)
            for (let m = 0; m < monthsByName.length; m++) {
                sortableMonths.push([months[monthsByName[m]], monthsByName[m]])
            }

            sortableMonths.sort(function(a, b) {
                return a[0] - b[0];
            });
            sortableMonths.reverse()
            this.statementMonths = sortableMonths
            this.sortTransactions()
            // this.transactions.splice(0, 1)
            }
            }else{
                this.transactions = null
                this.orlenData = null
                alert("Wystąpił błąd podczas pobierania transakcji")
            }
            this.searchingTransactions = false
        },
        translateDate(date, type){
            moment.locale('pl');
            let translatedDate = ""
            if(type === 'date'){
                translatedDate = moment(date, "YYYYMMDD").format('LL')
            }
            if(type === 'dateWithHour'){
                translatedDate = moment(date, "YYYYMMDD h:mm:ss").format('LLL')
            }
            if(type === 'LastUseDateWithHour'){
                translatedDate = moment(date, "YYYYMMDD h:mm:ss").format('LLLL')
            }
            return translatedDate
        },
        async downloadSatuses(){
            const statuses = await db.collection("Statuses").doc('cardStatuses').get()
            let statusesData = statuses.data().translations
            this.cardStatuses = statusesData
        },
        backToMyCards(){
            this.$router.push(`/dashboard/mycards`)
        },
        addSpaces(number){
            let num = number.toString()
            let joy = num.match(/.{1,4}/g);
            let withSpaces = joy.join(' ')
            return withSpaces
        },
        async findCard(){
            this.searchingCard = true
            this.card = {}
            const res = await axios.post("https://europe-central2-lauron-a8b59.cloudfunctions.net/api/getCard", {
            // const res = await axios.post("http://127.0.0.1:5001/lauron-a8b59/europe-central2/api/getCard", {
              PAN: `${this.$route.query.id}`,
              cardId: `${this.$route.query.cid}`,
              group: this.$store.state.userData.groupOfCards
            })
            if(res.data.success)
            {
                if(res.data.cards !== null){
                if(res.data.cards !== undefined){
                    res.data.cards.imgUrl = "https://firebasestorage.googleapis.com/v0/b/lauron-a8b59.appspot.com/o/assets%2F1_fseafaww1-min.jpeg?alt=media&token=37f53c89-0682-4407-9640-c949b44d7fda";
                    res.data.cards.loaded = false;
                this.card = res.data.cards
                for (let y = 0; y < this.cardStatuses.length; y++) {
                    if(this.card.StatusId === this.cardStatuses[y].statusId){
                        this.card.statusData = this.cardStatuses[y]
                    }
                }
                }
                else{
                    this.backToMyCards()
                }
                }else{
                    this.backToMyCards()
                    this.card = {}
                }
                this.searchingCard = false
            }else{
                this.backToMyCards()
                this.searchingCard = false
            }
        }
    },
    async created(){
        await this.downloadSatuses()
        await this.findCard()
        await this.findTransactions()
        await this.countPrices()
    }
}
</script>

<style scoped>

.text-shadow {
text-shadow: 1px -1px 2px rgba(245, 245, 245, 1);
  }
.text-card {
    font-size: 16px;
    font-size: 150%;
}
</style>