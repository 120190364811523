<template>
  <div id="login" class="h-screen bg-slate-200">
    <transition name="fade">
      <Loader v-if="$store.state.globalLoader.show" :header="$store.state.globalLoader.head" :key="$store.state.globalLoader.head" :subheader="$store.state.globalLoader.subheader"></Loader>
    </transition>
    <div class="min-h-full flex">
      <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6">
        <div class="mx-auto w-full max-w-md">
          <transition name="fade">
            <div class="rounded-md bg-red-50 p-4 transition-all mb-6" v-show="error">
              <div class="flex">
                <div class="ml-3">
                  <h3 class="text-sm font-medium text-red-800">
                    Coś poszło nie tak w trakcie procesu resetowania hasła. Podejmij następujące akcje:
                  </h3>
                  <div class="mt-2 text-sm text-red-700">
                    <ul role="list" class="list-disc pl-5 space-y-1">
                      <li>
                        Sprawdź czy link nie został wygenerowany ponad 15 minut temu.
                      </li>
                      <li>
                        Sprawdź połączenie z internetem. 
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <div>
            <h2 class="mt-6 text-3xl font-extrabold text-slate-900 text-center">
              Zresetuj hasło użytkownika
            </h2>
            <p v-if="linkError" class="mt-2 text-base text-red-500 text-center font-medium">
              Link do resetowania hasła jest nieprawidłowy lub wygasł. Spróbuj ponownie.
            </p>
            <button v-if="linkError" :class="false ? 'bg-gray-400 cursor-not-allowed' : 'hover:bg-blue-500 bg-blue-400 cursor-pointer'" @click.prevent="backToLogin()" type="submit" class="w-full flex justify-center py-2 px-4 h-10 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-300 mt-4">
              <div>Wróć do panelu logowania</div>
            </button>
          </div>

          <div v-if="!linkError && !loading" class="mt-6">
            <div>
              <div class="mt-6 relative">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="w-full border-t border-slate-400" />
                </div>
                <div class="relative flex justify-center text-sm">
                  <span class="px-2 bg-slate-200 text-slate-600">
                    Wpisz nowe hasło i kliknij w przycisk, aby zresetować hasło.
                  </span>
                </div>
                <div class="relative flex justify-center text-sm">
                  <span class="px-2 bg-slate-200 font-medium" :class="password.length < 6 || repeatPassword.length < 6 ? 'text-red-500' : 'text-green-500'">
                    Hasło musi zawierać co najmniej 6 znaków.
                  </span>
                </div>
              </div>
            </div>

            <div class="mt-6">
              <form action="#" method="POST" class="space-y-6">
                <div class="space-y-1">
                  <label for="password" class="block text-sm font-medium text-slate-700">
                    Hasło
                  </label>
                  <div class="mt-1">
                    <input :disabled="resetingPassword" :class="[resetingPassword ? 'opacity-30 pointer-events-none' : '']" id="password" placeholder="Hasło" name="password" v-model="password" type="password" autocomplete="current-password" required="" class="appearance-none block w-full px-3 py-2 border border-slate-300 rounded-md shadow-sm placeholder-slate-400 focus:outline-none focus:ring-blue-400 focus:border-blue-400 sm:text-sm transition-all" />
                  </div>
                </div>
                <div class="space-y-1">
                  <label for="password" class="block text-sm font-medium text-slate-700">
                    Powtórz hasło
                  </label>
                  <div class="mt-1">
                    <input :disabled="resetingPassword" :class="[resetingPassword ? 'opacity-30 pointer-events-none' : '']" id="password2" placeholder="Powtórz hasło" name="password" v-model="repeatPassword" type="password" autocomplete="current-password" required="" class="appearance-none block w-full px-3 py-2 border border-slate-300 rounded-md shadow-sm placeholder-slate-400 focus:outline-none focus:ring-blue-400 focus:border-blue-400 sm:text-sm transition-all" />
                  </div>
                  <div class="flex justify-end mt-1">
                    <div @click.prevent="backToLogin()" class="text-xs font-medium text-blue-600 hover:text-blue-500 transition-all duration-300 w-fit select-none cursor-pointer">Powrót do panelu logowania</div>
                  </div>
                </div>

                <div>
                  <button :disabled="resetingPassword || password.length < 6 || repeatPassword.length < 6 || checkIfPasswordsMatch()" :class="password.length < 6 || repeatPassword.length < 6 || checkIfPasswordsMatch() ? 'bg-gray-400 cursor-not-allowed' : 'hover:bg-blue-500 bg-blue-400 cursor-pointer'" @click.prevent="resetPassword" type="submit" class="w-full flex justify-center py-2 px-4 h-10 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-300">
                    <div v-if="resetingPassword" class="spinner"></div>
                    <div v-else>Zresetuj hasło</div>
                  </button>
                </div>

                <div class="text-xs text-slate-700 font-medium">
                  &copy; Powered by <a href="https://complaia.systems/" class="text-purple-500 hover:text-purple-400">Complaia Systems</a>.
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '../components/Loader.vue';
import axios from 'axios'

export default {
  name: "ResetPassword",
  components: {
    Loader
  },
   watch:{
    '$route'()
    {
      this.$store.commit("resetGlobalLoader");
    }
  },
  data: function () {
    return {
      error: false,
      password: "",
      repeatPassword: "",
      passwordReset: false,
      resetingPassword: false,
      linkError: false,
      loading: true
    }
  },
  methods: {
    backToLogin() {
      this.$router.push('/');
    },
    checkIfPasswordsMatch() {
      return this.password !== this.repeatPassword
    },
    async resetPassword() {
      try {
        this.resetingPassword = true

        const res = await axios.post("https://europe-central2-lauron-a8b59.cloudfunctions.net/api/resetPassword", {
            password: this.password,
            key: this.$route.query.key
        })
        if(res.data.success) {
          setTimeout(() => {            
            this.$store.commit('setNotification',{
              show: true,
              head: "Hasło zostało zmienione",
              subheader: `Możesz teraz zalogować się na swoje konto`,
              success: true
            }); 
            this.$router.push('/')
          }, 2000);
        } else {
          this.linkError = true
        }
      } catch(error) {
        this.$store.commit('setNotification',{
          show: true,
          head: "Pojawił się błąd przy resetowaniu hasła",
          subheader: `Zamknij okno i spróbuj ponownie`,
          success: false
        }); 
      }
    },
    async checkLink() {
      try {
        this.loading = true
        const key = this.$route.query.key;
        const res = await axios.post("https://europe-central2-lauron-a8b59.cloudfunctions.net/api/checkPasswordResetLink", {
            key: key
        })
        if(!res.data.success) {
          this.linkError = true
        }
        this.loading = false
        this.$store.commit("resetGlobalLoader");
      } catch(error) {
        console.log(error);
        this.linkError = true
        this.loading = false
        this.$store.commit("resetGlobalLoader");
      }
      

    }
  },
  created() {
    this.$store.commit('setGlobalLoader',{
      show: true,
      head: "Ładuje..",
      subheader: `Nie zamykaj tego okna.`,
    });
    this.checkLink()
  }
};
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.spinner {
   width: 1.4rem;
   height: 1.4rem;
   border-radius: 50%;
   border: 5px solid #ffffff;
   animation: spinner-bulqg1 1.3s infinite linear alternate,
        spinner-oaa3wk 2.6s infinite linear;
}

@keyframes spinner-bulqg1 {
   0% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
   }

   12.5% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
   }

   25% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
   }

   50% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
   }

   62.5% {
      clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
   }

   75% {
      clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
   }

   100% {
      clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
   }
}

@keyframes spinner-oaa3wk {
   0% {
      transform: scaleY(1) rotate(0deg);
   }

   49.99% {
      transform: scaleY(1) rotate(135deg);
   }

   50% {
      transform: scaleY(-1) rotate(0deg);
   }

   100% {
      transform: scaleY(-1) rotate(-135deg);
   }
}
</style>
